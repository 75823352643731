/// <reference types="anychart" />

import * as React from 'react';
import 'anychart';
import {createRef, useEffect, useRef} from "react";
import {DataShape} from "../../helpers/metric";
import {legendChangingColor} from "../../helpers/chart";

interface ParetoChartProps {
  values: DataShape[]
  otherValues: DataShape[]
  scaleValues?: string[] | number[]
  unit?: string
  colorSchema?: boolean
  maximum?: number
  small?: boolean
}

export default function ParetoLineChart({
                                          values,
                                          otherValues,
                                          scaleValues,
                                          unit = '%',
                                          colorSchema = true,
                                          maximum,
                                          small = false
                                        }: ParetoChartProps) {
  const ref = createRef<HTMLDivElement>()
  const column = anychart.line();
  const chartRef = useRef(column);

  useEffect(() => {
    chartRef.current && chartRef.current.dispose();
    const x = anychart.line();
    chartRef.current = x;

    // turn on chart animation
    // chartRef.current.animation(true);

    // set chart title text settings
    chartRef.current.title('');

    // temp variable to store series instance
    var series;

    // helper function to setup label settings for all series
    var setupSeries = function (series: anychart.core.cartesian.series.Spline, name: string, fill?: string) {
      series.name(name);
      if (fill) {
        series.stroke(fill)
      } else {
        const colorScale = anychart.scales.ordinalColor();
        colorScale.ranges([
          {
            less: 50,
            color: '#e65358'
          },
          {
            from: 50,
            to: 70,
            color: {
              angle: 90,
              keys: ['0.01 #e65358', '0.1 #ffd06e', '0.9 #ffd06e', '0.99 #77b77a']
            }
          },
          {
            greater: 70,
            color: '#77b77a'
          }
        ]);
        series.colorScale(colorScale);
        series.stroke(function () {
          // @ts-ignore
          return anychart.color.setThickness(this.scaledColor ?? legendChangingColor, 4);
        });
      }
    };

    var dataSetX = anychart.data.set(values);
    var dataSetY = anychart.data.set(otherValues);

    // create second series with mapped data
    series = chartRef.current.spline(dataSetX);
    series.xPointPosition(0.25);
    setupSeries(series, 'Vybrané období', colorSchema ? undefined : '4 #77b77a');

    // create first series with mapped data
    series = chartRef.current.spline(dataSetY);
    series.xPointPosition(0.45);
    setupSeries(series, 'Předchozí období', '4 #3b8ad9');

    // set chart padding
    chartRef.current.barGroupsPadding(0.3);

    // format numbers in y axis label to match browser locale
    // eslint-disable-next-line no-template-curly-in-string
    chartRef.current.yAxis().labels().format('{%Value}{groupsSeparator: }' + unit);

    // set titles for Y-axis
    chartRef.current.yAxis().title('');

    if (maximum) {
      chartRef.current.yScale().maximum(maximum);
    } else if (unit === '%') {
      chartRef.current.yScale().maximum(100);
      chartRef.current.yScale().minimum(0);
    }

    // turn on legend
    chartRef.current.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0]);

    var interactivity = chartRef.current.interactivity();
    interactivity.selectionMode("none");

    // eslint-disable-next-line no-template-curly-in-string
    chartRef.current.tooltip().format('{%Value}{groupsSeparator: }' + unit);

    scaleValues !== undefined && (chartRef.current.xScale() as unknown as anychart.scales.Ordinal).values(scaleValues);
    small && chartRef.current.xAxis().labels().fontSize(10).rotation(-45);

    ref.current && chartRef.current.container(ref.current).draw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current, values, scaleValues])

  return (
    <div ref={ref} style={{width: '100%', height: '100%'}}/>
  );
}
