import * as React from 'react';
import Avatar from "@mui/material/Avatar";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string, size: number) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: size,
      height: size,
      fontSize: size/2
    },
    children: `${name.split(' ')?.[0]?.[0]}${name.split(' ')?.[1]?.[0]}`,
  };
}

interface StringAvatarProps {
  fullName: string;
  size?: number
}

export default function StringAvatar({fullName, size = 40}: StringAvatarProps) {
  if (fullName === '') {
    return <Avatar sx={{width: size, height: size}} />;
  }
  return <Avatar {...stringAvatar(fullName, size)}/>
}
