/// <reference types="anychart" />

import * as React from 'react';
import 'anychart';
import {createRef, useEffect, useRef} from "react";
import {addOrRemove} from "../../helpers/array";

interface ParetoChartWithSumProps {
  values: {
    x: string,
    value: number,
    id: number,
    fill?: string | null
  }[],
  selected: number[],
  setSelected: (value: number[]) => void
}

export default function ParetoChartWithSum({values, selected, setSelected}: ParetoChartWithSumProps) {
  const ref = createRef<HTMLDivElement>()
  const column = anychart.pareto();
  const chartRef = useRef(column);

  const sorted = values.sort((a, b) => {
    if (a.value < b.value) return 1;
    if (b.value < a.value) return -1;
    return 0;
  })

  useEffect(() => {
    chartRef.current && chartRef.current.dispose();
    const x = anychart.pareto(sorted);
    chartRef.current = x;

    chartRef.current.listen('click', (e: any) => {
      const value = sorted[e.pointIndex].id;
      value && setSelected(addOrRemove(selected, value))
    })
    chartRef.current.getSeries(0).normal().stroke(0);
    const markers = chartRef.current.getSeries(1).normal().markers();
    markers.type('circle');
    markers.size(4);
    chartRef.current.getSeries(1).normal().stroke('2 #003A70');
    chartRef.current.getSeries(1).hovered().stroke('2 #003A70');
    chartRef.current.getSeries(1).normal().fill('#003A70');
    chartRef.current.getSeries(1).hovered().fill('#003A70');

    chartRef.current.getSeries(0).tooltip().format('Prostoj: {%Value}h');
    chartRef.current.getSeries(1)
      .tooltip()
      .format('Kumulovaný součet: {%CF}%');

    const interactivity = chartRef.current.interactivity();
    interactivity.selectionMode("none");

    ref.current && chartRef.current.container(ref.current).draw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current, sorted])

  useEffect(() => {
    // const selectedIndexes = sorted.map((value, index) => ({index, value})).filter(({value, index}) => selected.includes(value[2] || '')).map(({index}) => index);
    // chartRef.current.getSeries(0).select(selectedIndexes);
  }, [selected, sorted])

  return (
    <div ref={ref} style={{width: '100%', height: '100%'}} />
  );
}
