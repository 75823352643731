import React, {useContext, useState} from "react";
import {Tag, TagType3} from "../../openapi";
import {Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import TagFormModal from "./TagFormModal";
import EditIcon from "@mui/icons-material/Edit";
import {DataContext} from "../../context/DataContext";
import {ApiContext} from "../../context/ApiContext";

function Row({tag, handleEdit}: { tag: Tag, handleEdit: () => void }) {
  return (
    <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
      <TableCell component="th" scope="row">{tag.name}</TableCell>
      <TableCell component="th" scope="row">{tag.description}</TableCell>
      <TableCell component="th" scope="row"><Chip label={tag.color} sx={{backgroundColor: tag.color}} /></TableCell>
      <TableCell>
        <Fab size="small" color="primary" onClick={handleEdit}>
          <EditIcon/>
        </Fab>
      </TableCell>
    </TableRow>
  );
}

interface EditableTagListProps {
  tagType: TagType3
}

export default function EditableTagList({tagType}: EditableTagListProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [editedTag, setEditedTag] = useState<Tag | null>(null);
  const {reload} = useContext(DataContext);
  const {api} = useContext(ApiContext);

  const handleSave = (name: string, description: string, color: string) => {
    if (editedTag && editedTag.id) {
      api.putApiTagUpdate(editedTag.id.toString(), {name, description, color}).then(() => {
        reload();
        setModalOpen(false);
      })
    } else {
      api.postApiTagCreate({name, description, color}).then(({data}) => {
        if (data.idRecord) {
          api.postApiTagAddType(data.idRecord.toString() || '', tagType.id?.toString() || '').then(() => {
            reload();
            setModalOpen(false);
          })
        }
      })
    }
  }

  return (
    <div style={{padding: 20}}>
      <Typography gutterBottom variant="h2">{tagType.description}</Typography>
      <Fab
        color="secondary" aria-label="add"
        sx={{position: 'absolute', bottom: 32, right: 32}}
        onClick={() => {
          setModalOpen(true);
          setEditedTag(null)
        }}
      >
        <AddIcon/>
      </Fab>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Název</TableCell>
              <TableCell>Popisek</TableCell>
              <TableCell>Barva</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              tagType.tags?.map(tag => <Row tag={tag} handleEdit={() => {
                setModalOpen(true);
                setEditedTag(tag)
              }}/>)
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        modalOpen &&
          <TagFormModal isOpen={modalOpen} onSave={handleSave} tag={editedTag} handleClose={() => setModalOpen(false)}/>
      }
    </div>
  );
}
