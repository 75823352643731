import React, {useCallback, useContext, useEffect, useState} from "react";
import {
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
} from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import {ApiContext} from "../context/ApiContext";
import {User} from "../openapi";
import {DataContext} from "../context/DataContext";

function Row({
               id,
               title,
               email,
               role,
               handleDelete,
               reloadData
             }: { id: string, title: string, email: string, role: string, handleDelete: () => void, reloadData: () => void }) {
  const [editMode, setEditMode] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(title);
  const {api} = useContext(ApiContext);

  const hasChange = currentTitle !== title;

  return (
    <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
      <TableCell component="th" scope="row" onDoubleClick={() => setEditMode(!editMode)}>
        {editMode ? <input autoFocus={true} value={currentTitle} onChange={(e) => setCurrentTitle(e.target.value)}
                           onBlur={() => setEditMode(false)}/> : currentTitle}
      </TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{role}</TableCell>
      <TableCell>
        {
          hasChange &&
            <Fab size="small" color="info" sx={{mr: 2}} onClick={() => {
              api.putApiUserUpdate(id, {fullName: currentTitle, email: email, password: 'admin'}).then(() => reloadData())
            }
            }>
                <SaveIcon/>
            </Fab>
        }
        <Fab size="small" color="error" onClick={handleDelete}>
          <DeleteIcon/>
        </Fab>
      </TableCell>
    </TableRow>
  );
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function UserModal({
                     open,
                     handleClose,
                     reloadData
                   }: { open: boolean, handleClose: () => void, reloadData: () => void }) {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const fullNameError = fullName.length < 2;
  const emailError = email.length < 2 && !email.includes('@');
  const passwordError = password.length < 2;
  const passwordRepeatError = password !== passwordRepeat;

  const hasAnyError = fullNameError || emailError || passwordError || passwordRepeatError;

  const [fullNameTouched, setFullNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [passwordRepeatTouched, setPasswordRepeatTouched] = useState(false);

  const {api} = useContext(ApiContext)
  const saveUser = useCallback(() => {
    api.postApiUserCreate({fullName, email, password}).then(response => {
      if (response.status === 201) {
        reloadData();
        handleClose();
      } else {
        // handle error;
      }
    });
  }, [api, fullName, email, password, reloadData, handleClose])

  return (
    <Modal open={open} onClose={handleClose} keepMounted>
      <Box sx={style}>
        <Box py={1}>
          <TextField label="Jméno" value={fullName} onChange={e => {
            setFullName(e.target.value);
            setFullNameTouched(true)
          }} error={fullNameError && fullNameTouched}/>
        </Box>
        <Box py={1}>
          <TextField label="Email" value={email} onChange={e => {
            setEmail(e.target.value);
            setEmailTouched(true)
          }} error={emailError && emailTouched}/>
        </Box>
        <Box py={1}>
          <TextField type="password" label="Heslo" value={password} onChange={e => {
            setPassword(e.target.value);
            setPasswordTouched(true)
          }} error={passwordError && passwordTouched}/>
        </Box>
        <Box py={1}>
          <TextField type="password" label="Heslo znovu" value={passwordRepeat} onChange={e => {
            setPasswordRepeat(e.target.value);
            setPasswordRepeatTouched(true)
          }} error={passwordRepeatError && passwordRepeatTouched}/>
        </Box>
        <Box py={1} sx={{textAlign: 'right'}}>
          <Button variant="contained" size="large" onClick={() => saveUser()} disabled={hasAnyError}>Uložit</Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default function Users() {
  const {api} = useContext(ApiContext);
  const {reload} = useContext(DataContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<User[]>([]);

  const loadData = useCallback(() => {
    setLoading(true);
    reload();
    api.getApiUserAll().then(({data}) => {
      setData(data);
      setLoading(false)
    });
  }, [api, reload])

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div style={{padding: 20}}>
      <Fab color="secondary" aria-label="add" sx={{position: 'absolute', bottom: 32, right: 32}}
           onClick={() => setModalOpen(true)}>
        <AddIcon/>
      </Fab>
      {
        !loading &&
          <TableContainer component={Paper}>
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>Jméno</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Role</TableCell>
                          <TableCell></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      data.map(user => <Row key={user.email} id={(user.id || 0).toString()} title={user.fullName} email={user.email}
                                            role={user.roles?.join(', ') || ''} handleDelete={() => {
                        user.id && api.deleteApiUserRemove(user.id.toString()).then(() => loadData())
                      }
                      } reloadData={loadData}/>)
                    }
                  </TableBody>
              </Table>
          </TableContainer>
      }
      {
        <UserModal open={modalOpen} handleClose={() => setModalOpen(false)} reloadData={loadData}/>
      }
    </div>
  );
}
