import React from "react";
import {useMetricTrend} from "../data/metric";
import {getScaleValues, Range} from "../helpers/date";
import {Box, Card, CardContent, Typography} from "@mui/material";
import ParetoLineChart from "./charts/ParetoLineChart";
import Helper from "./Helper";

interface OeeTrendChartProps {
  from: Date
  to: Date
}

export default function OeeTrendChart({from, to}: OeeTrendChartProps) {
  const trendData = useMetricTrend(from, to, Range.Month, [], 'postApiMetricsOeeTrend', 'postApiMetricsOeeSum');
  const trendDataLastYear = useMetricTrend(from, to, Range.Month, [], 'postApiMetricsOeeTrend', 'postApiMetricsOeeSum', 'lastYear');

  return (
    <Card>
      <CardContent sx={{padding: 4, position: 'relative'}}>
        <Typography variant="h3" component="div">
          OEE za všechny provozy
          <Helper metric="oee"/>
        </Typography>
        <Typography gutterBottom variant="caption">Období za 1-12 měsíců (ukončený rok a aktuální rok)</Typography>
        <Box height={323}>
          <ParetoLineChart
            values={trendData}
            otherValues={trendDataLastYear}
            scaleValues={getScaleValues(Range.Month, [...trendData, ...trendDataLastYear])}
            small={true}
          />
        </Box>
      </CardContent>
    </Card>
  )
}
