import React, {useContext, useState} from "react";
import {Box, Card, CardContent, Container, Grid, Typography} from "@mui/material";
import ParetoChartWithSum from "./charts/ParetoChartWithSum";
import ColumnChart from "./charts/ColumnChart";
import {dateStringToKey, Range, trendFrom} from "../helpers/date";
import {mergeTrendData, TrendData, useInterventionData} from "../data/intervention";
import {format} from "date-fns";
import {roundToDecimalPlaces} from "../helpers/number";
import {DataContext} from "../context/DataContext";

interface ParetoChartProps {
  range: Range
  from: Date
  to: Date
  tags: number[]
  titleExtra?: string
}

export default function ParetoChart({from, to, range, tags, titleExtra = ''}: ParetoChartProps) {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([])
  const {tagAll} = useContext(DataContext);

  const data = useInterventionData(trendFrom(from, to, range), to, range, tags);
  const currentDateItem = range === Range.Custom ? {
    startDate: format(from, 'yyyy-MM-dd'),
    values: mergeTrendData(data?.map(({values}) => values) || [])
  } as TrendData : data?.find(item => item.startDate === format(from, 'yyyy-MM-dd'));

  const chartValues = currentDateItem?.values?.map(item => ({
    x: item.groupTagName,
    value: roundToDecimalPlaces(item.hours, 2),
    id: item.groupTagId,
    fill: tagAll?.find(tag => tag.id === item.groupTagId)?.color
  })) || [];

  const detailData = selectedIndices.map(indice =>
    (data?.map(({startDate, values}) => {
      const foundItemForIndice = values.find(value => value.groupTagId === indice)
      return {
        x: dateStringToKey(startDate, range),
        value: foundItemForIndice?.hours || 0,
        name: foundItemForIndice?.groupTagName,
        fill: tagAll.find(tag => tag.id === (indice as unknown))?.color,
      }
    })) || []) || []

  if (chartValues.length === 0) {
    return (
      <Box mt={2}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent sx={{padding: 3}}>
                  Pareto {titleExtra} žádná data
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent sx={{padding: 4}}>
                <Typography gutterBottom variant="h3" component="div">
                  Pareto {titleExtra}
                </Typography>
                <Box height={300}>
                  <ParetoChartWithSum
                    values={chartValues}
                    selected={selectedIndices}
                    setSelected={setSelectedIndices}
                  />
                </Box>
                {selectedIndices.length > 0 && <Box height={300}>
                    <ColumnChart values={detailData} unit={""}/>
                </Box>}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
