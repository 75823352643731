import React from "react";
import {Metric} from "../data/metric";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip';
import {styled} from "@mui/material/styles";

const StyledTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} children={children} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 13,
    padding: 8
  },
}));

interface HelperProps {
  metric: Metric
}

const tooltips = {
  oee: <><b style={{fontSize: 16}}>OEE</b><br />Celková efektivnost zařízení, anglicky Overall equipment effectiveness, je kvantitativním ukazatelem efektivnosti výrobních zařízení. Poskytuje měřitelné srovnání efektivnosti jednotlivých výrobních zařízení. Zahrnuje v sobě více složek ovlivňujících celkovou efektivnost, které lze samostatně vyhodnotit.</>,
  teep: <><b style={{fontSize: 16}}>TEEP</b><br />Totální efektivnost zařízení, anglicky Total Effective Equipment Performance (TEEP), je odvozeným ukazatelem metodiky OEE (celková efektivnost zařízení). TEEP posuzuje efektivnost zařízení vztaženou ke kalendářnímu času (tedy k 24 hodinám denně, 7 dnům v týdnu a 365 dnům v roce).</>,
  mttr: <><b style={{fontSize: 16}}>MTTR</b><br />Mean time to restore (střední doba do obnovení) nebo mean time to recovery (střední doba zotavení) - průměrná doba, po které se měřený subjekt navrátí do běžného stavu.</>,
  mtbf: <><b style={{fontSize: 16}}>MTBF</b><br />Střední doba mezi poruchami je statistická veličina, která slouží k ohodnocení spolehlivosti výrobku, nebo výrobního zařízení. Určuje se pro výrobek nebo zařízení, které se opravuje. U zařízení, které se neopravuje, se určuje střední doba do poruchy.</>,
}

export default function Helper({metric}: HelperProps) {
  return (
    <StyledTooltip arrow placement="top" style={{fontSize: 18}} title={tooltips[metric]}>
      <InfoOutlinedIcon sx={{ml: 1, cursor: 'help', position: 'absolute', top: 16, right: 16}} fontSize="small"/>
    </StyledTooltip>
  )
}
