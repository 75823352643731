/// <reference types="anychart" />

import * as React from 'react';
import 'anychart';
import {createRef, useEffect, useRef} from "react";

interface GaugeChartProps {
  value: number;
  showLabel?: boolean,
  thicker?: boolean
}

export default function GaugeChart({value, showLabel = true, thicker = false}: GaugeChartProps) {
  const ref = createRef<HTMLDivElement>()
  var circularGauge = anychart.gauges.circular();
  const chartRef = useRef(circularGauge);

  useEffect(() => {
    const data = [value];
    const axisMaximum = 100;
    data.push(axisMaximum);
    const dataSet = anychart.data.set(data);

    chartRef.current.data(dataSet);
    chartRef.current.fill('#fff')
      .stroke(0)
      .padding(0)
      .startAngle(0)
      .sweepAngle(360);

    if(!showLabel) {
      chartRef.current.tooltip().enabled(false);
    } else {
      chartRef.current.tooltip().format(((e: any) => e.value === 100 ? '100%'  :`Hodnota ${e.value}%`) as any)
    }

    // // enable pointer animation
    // animateGauge(chartRef.current);

    const circularAxis = chartRef.current.axis().radius(100).width(1).fill('transparent');
    circularAxis.scale().minimum(0);
    circularAxis.scale().maximum(axisMaximum);
    circularAxis.labels().enabled(false);
    circularAxis.ticks().enabled(false);
    circularAxis.minorTicks().enabled(false);

    const color = value > 70 ? '#77b77a' : value > 50 ? '#ffd06e' : '#e65358'
    chartRef.current.bar(0).dataIndex(0)
      .radius(100)
      .width(thicker ? 50 : 30)
      .fill(color)
      .stroke(0)
      .zIndex(5);

    chartRef.current.bar(1).dataIndex(1)
      .radius(100)
      .width(thicker ? 50 : 30)
      .fill('#cecece')
      .stroke(0)
      .zIndex(3);

    if (showLabel) {
      const circularLabel0 = chartRef.current.label(0);
      circularLabel0
        .enabled(true)
        .anchor('center')
        .useHtml(true)
        .hAlign("center")
        .text(data[0] + '%')
        .fontColor('#555555')
        .fontSize(20);
    }

    if (thicker) {
      chartRef.current.bounds('10%', '10%', '80%', '80%');
    } else {
      chartRef.current.bounds('25%','25%', '50%', '50%');
    }

    ref.current && chartRef.current.container(ref.current).draw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current, value])

  return (
    <div ref={ref} style={{width: '100%', height: '100%'}}/>
  );
}
