import Box from "@mui/material/Box";
import React from "react";
import {
  Routes as RouterRoutes,
  Route,
} from "react-router-dom";
import {useFiltration} from "../useFiltration";
import SideBar from "../components/SideBar";
import FilterBar from "../components/FilterBar";
import DateRangeSelection from "../components/DateRangeSelection";
import Oee from "./Oee";
import Teep from "./Teep";
import Mttr from "./Mttr";
import Mtbf from "./Mtbf";
import Pareto from "./Pareto";

export default function DataRoutes() {
  const {tags, setTags, range, setRange, from, setFrom, to, setTo, groups} = useFiltration();

  return (
    <Box sx={{display: 'flex'}}>
      <SideBar/>
      <Box component="main" sx={{flexGrow: 1, p: 3, pt: 0, overflow: 'auto', height: 'calc(100vh - 64px)'}}>
        <FilterBar values={tags} setValues={setTags}/>
        <DateRangeSelection range={range} setRange={setRange} from={from} setFrom={setFrom} to={to} setTo={setTo}/>
        <RouterRoutes>
          <Route path="oee" element={<Oee from={from} to={to} groups={groups} range={range} tags={tags}/>} />
          <Route path="teep" element={<Teep from={from} to={to} groups={groups} range={range} tags={tags}/>} />
          <Route path="mttr" element={<Mttr from={from} to={to} groups={groups} range={range} tags={tags}/>} />
          <Route path="mtbf" element={<Mtbf from={from} to={to} groups={groups} range={range} tags={tags}/>} />
          <Route path="pareto" element={<Pareto from={from} to={to} groups={groups} range={range} tags={tags}/>} />
        </RouterRoutes>
      </Box>
    </Box>
  );
}
