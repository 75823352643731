import React, {useContext} from "react";
import {Box, Card, CardContent, Typography} from "@mui/material";
import PieChart from "./charts/PieChart";
import {DataContext} from "../context/DataContext";
import {useInterventionData} from "../data/intervention";
import {format} from "date-fns";
import {roundToDecimalPlaces} from "../helpers/number";
import {Range} from "../helpers/date";

interface TopCompaniesChartProps {
  from: Date
  to: Date
}

export default function ReasonsChart({from ,to}: TopCompaniesChartProps) {
  const {tagAll} = useContext(DataContext);

  const data = useInterventionData(from, to, Range.Week, []);
  const currentDateItem = data?.find(item => item.startDate === format(from, 'yyyy-MM-dd'));
  const chartValues = currentDateItem?.values.map(item => ({
    x: item.groupTagName,
    value: roundToDecimalPlaces(item.hours, 2),
    fill: tagAll.find(tag => tag.id === item.groupTagId)?.color
  })) || [];

  return (
    <Card>
      <CardContent sx={{padding: 4}}>
        <Typography gutterBottom variant="h3" component="div">
          Důvody prostojů ve všech provozech
        </Typography>
        <Box height={340}>
          <PieChart values={chartValues} />
        </Box>
      </CardContent>
    </Card>
  );
}
