import React, {useContext, useState} from "react";
import {ApiContext} from "../context/ApiContext";
import {GraphSummaryResponse, GraphTrendResponse} from "../openapi";

export default function TestPage() {
  const {api} = useContext(ApiContext);

  const [oeeSum, setOeeSum] = useState<GraphSummaryResponse>();
  const [oeeTrend, setOeeTrend] = useState<GraphTrendResponse>();

  const [from, setFrom] = useState('2022-08-01T00:00:00.000Z');
  const [to, setTo] = useState('2022-08-31T23:59:59.999Z');
  const [tags, setTags] = useState('');
  const [period, setPeriod] = useState('');

  const loadData = () => {
    api.postApiMetricsOeeSum({
      intervalStart: from,
      intervalEnd: to,
      period: period,
      tags: tags
    }).then((response) => setOeeSum(response.data))

    api.postApiMetricsOeeTrend({
      intervalStart: from,
      intervalEnd: to,
      period: period,
      tags: tags
    }).then((response) => setOeeTrend(response.data))
  }

  return (
    <div style={{padding: 20}}>
      <label>
        Od
        <input value={from} onChange={e => setFrom(e.target.value)}/>
      </label>
      <label>
        Do
        <input value={to} onChange={e => setTo(e.target.value)}/>
      </label>
      <label>
        Tagy
        <input value={tags} onChange={e => setTags(e.target.value)}/>
      </label>
      <label>
        Perioda
        <input value={period} onChange={e => setPeriod(e.target.value)}/>
      </label>
      <button onClick={loadData}>Odeslat</button>

      <pre style={{border: '1px solid black', padding: 10}}>OEE Sum<br /><br />{JSON.stringify(oeeSum, null, 2)}</pre>
      <pre style={{border: '1px solid black', padding: 10}}>Oee Trend<br /><br />{JSON.stringify(oeeTrend, null, 2)}</pre>
    </div>
  );
}
