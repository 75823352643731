/* tslint:disable */
/* eslint-disable */
/**
 * OEE
 * OEE app.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiBadRequestResponse
 */
export interface ApiBadRequestResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiBadRequestResponse
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiEndpoint
 */
export interface ApiEndpoint {
    /**
     * 
     * @type {number}
     * @memberof ApiEndpoint
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiEndpoint
     */
    'urlForToken': string;
    /**
     * 
     * @type {string}
     * @memberof ApiEndpoint
     */
    'endpoint': string;
    /**
     * 
     * @type {string}
     * @memberof ApiEndpoint
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiEndpoint
     */
    'clientSecret': string;
    /**
     * 
     * @type {Tag}
     * @memberof ApiEndpoint
     */
    'tag'?: Tag | null;
}
/**
 * 
 * @export
 * @interface ApiNotFoundResponse
 */
export interface ApiNotFoundResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiNotFoundResponse
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiSuccessResponse
 */
export interface ApiSuccessResponse {
    /**
     * 
     * @type {number}
     * @memberof ApiSuccessResponse
     */
    'idRecord'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiSuccessResponse
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface EndpointType
 */
export interface EndpointType {
    /**
     * 
     * @type {string}
     * @memberof EndpointType
     */
    'urlForToken': string;
    /**
     * 
     * @type {string}
     * @memberof EndpointType
     */
    'endpoint': string;
    /**
     * 
     * @type {string}
     * @memberof EndpointType
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof EndpointType
     */
    'clientSecret': string;
}
/**
 * 
 * @export
 * @interface EndpointType2
 */
export interface EndpointType2 {
    /**
     * 
     * @type {string}
     * @memberof EndpointType2
     */
    'urlForToken': string;
    /**
     * 
     * @type {string}
     * @memberof EndpointType2
     */
    'endpoint': string;
    /**
     * 
     * @type {string}
     * @memberof EndpointType2
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof EndpointType2
     */
    'clientSecret': string;
}
/**
 * 
 * @export
 * @interface GraphSummaryResponse
 */
export interface GraphSummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof GraphSummaryResponse
     */
    'data'?: number;
    /**
     * 
     * @type {string}
     * @memberof GraphSummaryResponse
     */
    'graphName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GraphSummaryResponse
     */
    'intervalStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof GraphSummaryResponse
     */
    'intervalEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof GraphSummaryResponse
     */
    'period'?: string;
}
/**
 * 
 * @export
 * @interface GraphTrendResponse
 */
export interface GraphTrendResponse {
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof GraphTrendResponse
     */
    'data'?: Array<Array<string>>;
    /**
     * 
     * @type {string}
     * @memberof GraphTrendResponse
     */
    'graphName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GraphTrendResponse
     */
    'intervalStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof GraphTrendResponse
     */
    'intervalEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof GraphTrendResponse
     */
    'period'?: string;
}
/**
 * 
 * @export
 * @interface InterventionGroupDto
 */
export interface InterventionGroupDto {
    /**
     * 
     * @type {number}
     * @memberof InterventionGroupDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InterventionGroupDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InterventionGroupDto
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InterventionGroupDto
     */
    'genericInterventionGroupName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InterventionGroupDto
     */
    'ownerMachine'?: number | null;
}
/**
 * 
 * @export
 * @interface InterventionsTrendResponse
 */
export interface InterventionsTrendResponse {
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof InterventionsTrendResponse
     */
    'data'?: Array<Array<string>>;
    /**
     * 
     * @type {string}
     * @memberof InterventionsTrendResponse
     */
    'graphName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InterventionsTrendResponse
     */
    'intervalStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof InterventionsTrendResponse
     */
    'intervalEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof InterventionsTrendResponse
     */
    'period'?: string;
}
/**
 * 
 * @export
 * @interface Machine
 */
export interface Machine {
    /**
     * 
     * @type {number}
     * @memberof Machine
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    'internalNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Machine
     */
    'tags'?: Array<Tag>;
    /**
     * 
     * @type {boolean}
     * @memberof Machine
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface MachineType
 */
export interface MachineType {
    /**
     * 
     * @type {string}
     * @memberof MachineType
     */
    'internalNumber': string;
    /**
     * 
     * @type {string}
     * @memberof MachineType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MachineType
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface MachineType2
 */
export interface MachineType2 {
    /**
     * 
     * @type {string}
     * @memberof MachineType2
     */
    'internalNumber': string;
    /**
     * 
     * @type {string}
     * @memberof MachineType2
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MachineType2
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface MetricsType
 */
export interface MetricsType {
    /**
     * 
     * @type {string}
     * @memberof MetricsType
     */
    'tags': string;
    /**
     * 
     * @type {string}
     * @memberof MetricsType
     */
    'intervalStart': string;
    /**
     * 
     * @type {string}
     * @memberof MetricsType
     */
    'intervalEnd': string;
    /**
     * 
     * @type {string}
     * @memberof MetricsType
     */
    'period': string;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'description'?: string | null;
    /**
     * 
     * @type {TagType3}
     * @memberof Tag
     */
    'type'?: TagType3 | null;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'color'?: string | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Tag
     */
    'tagsWithMe'?: Array<Tag>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Tag
     */
    'myTags'?: Array<Tag>;
    /**
     * 
     * @type {Array<Machine>}
     * @memberof Tag
     */
    'machines'?: Array<Machine>;
}
/**
 * 
 * @export
 * @interface TagType
 */
export interface TagType {
    /**
     * 
     * @type {string}
     * @memberof TagType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TagType
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagType
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface TagType2
 */
export interface TagType2 {
    /**
     * 
     * @type {string}
     * @memberof TagType2
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TagType2
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagType2
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface TagType3
 */
export interface TagType3 {
    /**
     * 
     * @type {number}
     * @memberof TagType3
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagType3
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TagType3
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof TagType3
     */
    'tags'?: Array<Tag>;
}
/**
 * 
 * @export
 * @interface TypeTagType
 */
export interface TypeTagType {
    /**
     * 
     * @type {string}
     * @memberof TypeTagType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TypeTagType
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface TypeTagType2
 */
export interface TypeTagType2 {
    /**
     * 
     * @type {string}
     * @memberof TypeTagType2
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TypeTagType2
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'dashboard'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'roles'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserType
 */
export interface UserType {
    /**
     * 
     * @type {string}
     * @memberof UserType
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof UserType
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserType
     */
    'dashboard'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserType
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UserType2
 */
export interface UserType2 {
    /**
     * 
     * @type {string}
     * @memberof UserType2
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof UserType2
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserType2
     */
    'dashboard'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserType2
     */
    'password': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        afccfb0c51a5090b52093e646b29d9dc: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiEndpointRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiEndpointRemove', 'id', id)
            const localVarPath = `/api/endpoint/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMachineRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiMachineRemove', 'id', id)
            const localVarPath = `/api/machine/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMachineTagRemove: async (id: string, idTag: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiMachineTagRemove', 'id', id)
            // verify required parameter 'idTag' is not null or undefined
            assertParamExists('deleteApiMachineTagRemove', 'idTag', idTag)
            const localVarPath = `/api/machine/{id}/tag/{idTag}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"idTag"}}`, encodeURIComponent(String(idTag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTagRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiTagRemove', 'id', id)
            const localVarPath = `/api/tag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} idOwner 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTagRemoveChildrenTag: async (idOwner: string, idTag: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idOwner' is not null or undefined
            assertParamExists('deleteApiTagRemoveChildrenTag', 'idOwner', idOwner)
            // verify required parameter 'idTag' is not null or undefined
            assertParamExists('deleteApiTagRemoveChildrenTag', 'idTag', idTag)
            const localVarPath = `/api/tag/{idOwner}/children/{idTag}`
                .replace(`{${"idOwner"}}`, encodeURIComponent(String(idOwner)))
                .replace(`{${"idTag"}}`, encodeURIComponent(String(idTag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTagRemoveType: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiTagRemoveType', 'id', id)
            const localVarPath = `/api/tag/{id}/type`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTagTypeRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiTagTypeRemove', 'id', id)
            const localVarPath = `/api/tag-type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiUserRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiUserRemove', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiEndpointAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/endpoint/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiInterventionsDefault: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/interventions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMachineAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/machine/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMachineSelectId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiMachineSelectId', 'id', id)
            const localVarPath = `/api/machine/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTagAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tag/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTagSelectId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiTagSelectId', 'id', id)
            const localVarPath = `/api/tag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTagTypeAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tag-type/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTagTypeSelectId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiTagTypeSelectId', 'id', id)
            const localVarPath = `/api/tag-type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUserAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUserLogged: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUserSelectId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiUserSelectId', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EndpointType} endpointType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiEndpointCreate: async (endpointType: EndpointType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'endpointType' is not null or undefined
            assertParamExists('postApiEndpointCreate', 'endpointType', endpointType)
            const localVarPath = `/api/endpoint/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endpointType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MachineType} machineType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMachineCreate: async (machineType: MachineType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'machineType' is not null or undefined
            assertParamExists('postApiMachineCreate', 'machineType', machineType)
            const localVarPath = `/api/machine/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(machineType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMachineTagAdd: async (id: string, idTag: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postApiMachineTagAdd', 'id', id)
            // verify required parameter 'idTag' is not null or undefined
            assertParamExists('postApiMachineTagAdd', 'idTag', idTag)
            const localVarPath = `/api/machine/{id}/tag/{idTag}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"idTag"}}`, encodeURIComponent(String(idTag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ==================================================================== Interventions ====================================================================
         * @param {MetricsType} metricsType Interventions trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsInterventions: async (metricsType: MetricsType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricsType' is not null or undefined
            assertParamExists('postApiMetricsInterventions', 'metricsType', metricsType)
            const localVarPath = `/api/metrics/interventions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricsType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ==================================================================== MTBF ====================================================================
         * @param {MetricsType} metricsType MTBF summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsMtbfSum: async (metricsType: MetricsType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricsType' is not null or undefined
            assertParamExists('postApiMetricsMtbfSum', 'metricsType', metricsType)
            const localVarPath = `/api/metrics/mtbf-sum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricsType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MetricsType} metricsType MTBF trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsMtbfTrend: async (metricsType: MetricsType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricsType' is not null or undefined
            assertParamExists('postApiMetricsMtbfTrend', 'metricsType', metricsType)
            const localVarPath = `/api/metrics/mtbf-trend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricsType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ==================================================================== MTTR ====================================================================
         * @param {MetricsType} metricsType MTTR summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsMttrSum: async (metricsType: MetricsType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricsType' is not null or undefined
            assertParamExists('postApiMetricsMttrSum', 'metricsType', metricsType)
            const localVarPath = `/api/metrics/mttr-sum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricsType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MetricsType} metricsType MTTR trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsMttrTrend: async (metricsType: MetricsType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricsType' is not null or undefined
            assertParamExists('postApiMetricsMttrTrend', 'metricsType', metricsType)
            const localVarPath = `/api/metrics/mttr-trend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricsType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ==================================================================== OEE ====================================================================
         * @param {MetricsType} metricsType Oee summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsOeeSum: async (metricsType: MetricsType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricsType' is not null or undefined
            assertParamExists('postApiMetricsOeeSum', 'metricsType', metricsType)
            const localVarPath = `/api/metrics/oee-sum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricsType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MetricsType} metricsType Oee trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsOeeTrend: async (metricsType: MetricsType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricsType' is not null or undefined
            assertParamExists('postApiMetricsOeeTrend', 'metricsType', metricsType)
            const localVarPath = `/api/metrics/oee-trend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricsType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ==================================================================== TEEP ====================================================================
         * @param {MetricsType} metricsType Teep summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsTeepSum: async (metricsType: MetricsType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricsType' is not null or undefined
            assertParamExists('postApiMetricsTeepSum', 'metricsType', metricsType)
            const localVarPath = `/api/metrics/teep-sum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricsType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MetricsType} metricsType Teep trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsTeepTrend: async (metricsType: MetricsType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricsType' is not null or undefined
            assertParamExists('postApiMetricsTeepTrend', 'metricsType', metricsType)
            const localVarPath = `/api/metrics/teep-trend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricsType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} idOwner 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiTagAddChildrenTag: async (idOwner: string, idTag: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idOwner' is not null or undefined
            assertParamExists('postApiTagAddChildrenTag', 'idOwner', idOwner)
            // verify required parameter 'idTag' is not null or undefined
            assertParamExists('postApiTagAddChildrenTag', 'idTag', idTag)
            const localVarPath = `/api/tag/{idOwner}/children/{idTag}`
                .replace(`{${"idOwner"}}`, encodeURIComponent(String(idOwner)))
                .replace(`{${"idTag"}}`, encodeURIComponent(String(idTag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiTagAddType: async (id: string, idType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postApiTagAddType', 'id', id)
            // verify required parameter 'idType' is not null or undefined
            assertParamExists('postApiTagAddType', 'idType', idType)
            const localVarPath = `/api/tag/{id}/type/{idType}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"idType"}}`, encodeURIComponent(String(idType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TagType} tagType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiTagCreate: async (tagType: TagType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagType' is not null or undefined
            assertParamExists('postApiTagCreate', 'tagType', tagType)
            const localVarPath = `/api/tag/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TypeTagType} typeTagType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiTagTypeCreate: async (typeTagType: TypeTagType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeTagType' is not null or undefined
            assertParamExists('postApiTagTypeCreate', 'typeTagType', typeTagType)
            const localVarPath = `/api/tag-type/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeTagType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserType} userType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiUserCreate: async (userType: UserType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userType' is not null or undefined
            assertParamExists('postApiUserCreate', 'userType', userType)
            const localVarPath = `/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiEndpointAddTag: async (id: string, idTag: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiEndpointAddTag', 'id', id)
            // verify required parameter 'idTag' is not null or undefined
            assertParamExists('putApiEndpointAddTag', 'idTag', idTag)
            const localVarPath = `/api/endpoint/{id}/tag/{idTag}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"idTag"}}`, encodeURIComponent(String(idTag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {EndpointType2} endpointType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiEndpointUpdate: async (id: string, endpointType2: EndpointType2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiEndpointUpdate', 'id', id)
            // verify required parameter 'endpointType2' is not null or undefined
            assertParamExists('putApiEndpointUpdate', 'endpointType2', endpointType2)
            const localVarPath = `/api/endpoint/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endpointType2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiInterventionsSetGroup: async (id: string, idGroup: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiInterventionsSetGroup', 'id', id)
            // verify required parameter 'idGroup' is not null or undefined
            assertParamExists('putApiInterventionsSetGroup', 'idGroup', idGroup)
            const localVarPath = `/api/interventions/{id}/group/{idGroup}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"idGroup"}}`, encodeURIComponent(String(idGroup)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMachineActivateMachine: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiMachineActivateMachine', 'id', id)
            const localVarPath = `/api/machine/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMachineDeactivateMachine: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiMachineDeactivateMachine', 'id', id)
            const localVarPath = `/api/machine/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MachineType2} machineType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMachineUpdate: async (id: string, machineType2: MachineType2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiMachineUpdate', 'id', id)
            // verify required parameter 'machineType2' is not null or undefined
            assertParamExists('putApiMachineUpdate', 'machineType2', machineType2)
            const localVarPath = `/api/machine/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(machineType2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {TypeTagType2} typeTagType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiTagTypeUpdate: async (id: string, typeTagType2: TypeTagType2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiTagTypeUpdate', 'id', id)
            // verify required parameter 'typeTagType2' is not null or undefined
            assertParamExists('putApiTagTypeUpdate', 'typeTagType2', typeTagType2)
            const localVarPath = `/api/tag-type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeTagType2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {TagType2} tagType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiTagUpdate: async (id: string, tagType2: TagType2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiTagUpdate', 'id', id)
            // verify required parameter 'tagType2' is not null or undefined
            assertParamExists('putApiTagUpdate', 'tagType2', tagType2)
            const localVarPath = `/api/tag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagType2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserType2} userType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiUserUpdate: async (id: string, userType2: UserType2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiUserUpdate', 'id', id)
            // verify required parameter 'userType2' is not null or undefined
            assertParamExists('putApiUserUpdate', 'userType2', userType2)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userType2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async afccfb0c51a5090b52093e646b29d9dc(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.afccfb0c51a5090b52093e646b29d9dc(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiEndpointRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiEndpointRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiMachineRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiMachineRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiMachineTagRemove(id: string, idTag: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiMachineTagRemove(id, idTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiTagRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiTagRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} idOwner 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiTagRemoveChildrenTag(idOwner: string, idTag: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiTagRemoveChildrenTag(idOwner, idTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiTagRemoveType(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiTagRemoveType(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiTagTypeRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiTagTypeRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiUserRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiUserRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiEndpointAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiEndpoint>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiEndpointAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiInterventionsDefault(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InterventionGroupDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiInterventionsDefault(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMachineAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Machine>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMachineAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMachineSelectId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Machine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMachineSelectId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiTagAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiTagAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiTagSelectId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiTagSelectId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiTagTypeAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagType3>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiTagTypeAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiTagTypeSelectId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagType3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiTagTypeSelectId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUserAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUserAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUserLogged(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUserLogged(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUserSelectId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUserSelectId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EndpointType} endpointType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiEndpointCreate(endpointType: EndpointType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiEndpointCreate(endpointType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MachineType} machineType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMachineCreate(machineType: MachineType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMachineCreate(machineType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMachineTagAdd(id: string, idTag: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMachineTagAdd(id, idTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ==================================================================== Interventions ====================================================================
         * @param {MetricsType} metricsType Interventions trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMetricsInterventions(metricsType: MetricsType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterventionsTrendResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMetricsInterventions(metricsType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ==================================================================== MTBF ====================================================================
         * @param {MetricsType} metricsType MTBF summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMetricsMtbfSum(metricsType: MetricsType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMetricsMtbfSum(metricsType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MetricsType} metricsType MTBF trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMetricsMtbfTrend(metricsType: MetricsType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphTrendResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMetricsMtbfTrend(metricsType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ==================================================================== MTTR ====================================================================
         * @param {MetricsType} metricsType MTTR summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMetricsMttrSum(metricsType: MetricsType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMetricsMttrSum(metricsType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MetricsType} metricsType MTTR trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMetricsMttrTrend(metricsType: MetricsType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphTrendResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMetricsMttrTrend(metricsType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ==================================================================== OEE ====================================================================
         * @param {MetricsType} metricsType Oee summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMetricsOeeSum(metricsType: MetricsType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMetricsOeeSum(metricsType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MetricsType} metricsType Oee trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMetricsOeeTrend(metricsType: MetricsType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphTrendResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMetricsOeeTrend(metricsType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ==================================================================== TEEP ====================================================================
         * @param {MetricsType} metricsType Teep summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMetricsTeepSum(metricsType: MetricsType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMetricsTeepSum(metricsType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MetricsType} metricsType Teep trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMetricsTeepTrend(metricsType: MetricsType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphTrendResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMetricsTeepTrend(metricsType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} idOwner 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiTagAddChildrenTag(idOwner: string, idTag: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiTagAddChildrenTag(idOwner, idTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiTagAddType(id: string, idType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiTagAddType(id, idType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TagType} tagType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiTagCreate(tagType: TagType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiTagCreate(tagType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TypeTagType} typeTagType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiTagTypeCreate(typeTagType: TypeTagType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiTagTypeCreate(typeTagType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserType} userType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiUserCreate(userType: UserType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiUserCreate(userType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiEndpointAddTag(id: string, idTag: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiEndpointAddTag(id, idTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {EndpointType2} endpointType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiEndpointUpdate(id: string, endpointType2: EndpointType2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiEndpointUpdate(id, endpointType2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiInterventionsSetGroup(id: string, idGroup: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiInterventionsSetGroup(id, idGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiMachineActivateMachine(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiMachineActivateMachine(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiMachineDeactivateMachine(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiMachineDeactivateMachine(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MachineType2} machineType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiMachineUpdate(id: string, machineType2: MachineType2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiMachineUpdate(id, machineType2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {TypeTagType2} typeTagType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiTagTypeUpdate(id: string, typeTagType2: TypeTagType2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiTagTypeUpdate(id, typeTagType2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {TagType2} tagType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiTagUpdate(id: string, tagType2: TagType2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiTagUpdate(id, tagType2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UserType2} userType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiUserUpdate(id: string, userType2: UserType2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiUserUpdate(id, userType2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        afccfb0c51a5090b52093e646b29d9dc(options?: any): AxiosPromise<void> {
            return localVarFp.afccfb0c51a5090b52093e646b29d9dc(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiEndpointRemove(id: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.deleteApiEndpointRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMachineRemove(id: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.deleteApiMachineRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMachineTagRemove(id: string, idTag: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.deleteApiMachineTagRemove(id, idTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTagRemove(id: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.deleteApiTagRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} idOwner 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTagRemoveChildrenTag(idOwner: string, idTag: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.deleteApiTagRemoveChildrenTag(idOwner, idTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTagRemoveType(id: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.deleteApiTagRemoveType(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTagTypeRemove(id: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.deleteApiTagTypeRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiUserRemove(id: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.deleteApiUserRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiEndpointAll(options?: any): AxiosPromise<Array<ApiEndpoint>> {
            return localVarFp.getApiEndpointAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiInterventionsDefault(options?: any): AxiosPromise<Array<InterventionGroupDto>> {
            return localVarFp.getApiInterventionsDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMachineAll(options?: any): AxiosPromise<Array<Machine>> {
            return localVarFp.getApiMachineAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMachineSelectId(id: string, options?: any): AxiosPromise<Machine> {
            return localVarFp.getApiMachineSelectId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTagAll(options?: any): AxiosPromise<Array<Tag>> {
            return localVarFp.getApiTagAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTagSelectId(id: string, options?: any): AxiosPromise<Tag> {
            return localVarFp.getApiTagSelectId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTagTypeAll(options?: any): AxiosPromise<Array<TagType3>> {
            return localVarFp.getApiTagTypeAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTagTypeSelectId(id: string, options?: any): AxiosPromise<TagType3> {
            return localVarFp.getApiTagTypeSelectId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUserAll(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getApiUserAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUserLogged(options?: any): AxiosPromise<User> {
            return localVarFp.getApiUserLogged(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUserSelectId(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.getApiUserSelectId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EndpointType} endpointType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiEndpointCreate(endpointType: EndpointType, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.postApiEndpointCreate(endpointType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MachineType} machineType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMachineCreate(machineType: MachineType, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.postApiMachineCreate(machineType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMachineTagAdd(id: string, idTag: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.postApiMachineTagAdd(id, idTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ==================================================================== Interventions ====================================================================
         * @param {MetricsType} metricsType Interventions trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsInterventions(metricsType: MetricsType, options?: any): AxiosPromise<InterventionsTrendResponse> {
            return localVarFp.postApiMetricsInterventions(metricsType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ==================================================================== MTBF ====================================================================
         * @param {MetricsType} metricsType MTBF summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsMtbfSum(metricsType: MetricsType, options?: any): AxiosPromise<GraphSummaryResponse> {
            return localVarFp.postApiMetricsMtbfSum(metricsType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MetricsType} metricsType MTBF trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsMtbfTrend(metricsType: MetricsType, options?: any): AxiosPromise<GraphTrendResponse> {
            return localVarFp.postApiMetricsMtbfTrend(metricsType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ==================================================================== MTTR ====================================================================
         * @param {MetricsType} metricsType MTTR summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsMttrSum(metricsType: MetricsType, options?: any): AxiosPromise<GraphSummaryResponse> {
            return localVarFp.postApiMetricsMttrSum(metricsType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MetricsType} metricsType MTTR trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsMttrTrend(metricsType: MetricsType, options?: any): AxiosPromise<GraphTrendResponse> {
            return localVarFp.postApiMetricsMttrTrend(metricsType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ==================================================================== OEE ====================================================================
         * @param {MetricsType} metricsType Oee summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsOeeSum(metricsType: MetricsType, options?: any): AxiosPromise<GraphSummaryResponse> {
            return localVarFp.postApiMetricsOeeSum(metricsType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MetricsType} metricsType Oee trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsOeeTrend(metricsType: MetricsType, options?: any): AxiosPromise<GraphTrendResponse> {
            return localVarFp.postApiMetricsOeeTrend(metricsType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ==================================================================== TEEP ====================================================================
         * @param {MetricsType} metricsType Teep summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsTeepSum(metricsType: MetricsType, options?: any): AxiosPromise<GraphSummaryResponse> {
            return localVarFp.postApiMetricsTeepSum(metricsType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MetricsType} metricsType Teep trend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMetricsTeepTrend(metricsType: MetricsType, options?: any): AxiosPromise<GraphTrendResponse> {
            return localVarFp.postApiMetricsTeepTrend(metricsType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} idOwner 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiTagAddChildrenTag(idOwner: string, idTag: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.postApiTagAddChildrenTag(idOwner, idTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiTagAddType(id: string, idType: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.postApiTagAddType(id, idType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TagType} tagType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiTagCreate(tagType: TagType, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.postApiTagCreate(tagType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TypeTagType} typeTagType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiTagTypeCreate(typeTagType: TypeTagType, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.postApiTagTypeCreate(typeTagType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserType} userType create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiUserCreate(userType: UserType, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.postApiUserCreate(userType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiEndpointAddTag(id: string, idTag: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.putApiEndpointAddTag(id, idTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {EndpointType2} endpointType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiEndpointUpdate(id: string, endpointType2: EndpointType2, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.putApiEndpointUpdate(id, endpointType2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} idGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiInterventionsSetGroup(id: string, idGroup: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.putApiInterventionsSetGroup(id, idGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMachineActivateMachine(id: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.putApiMachineActivateMachine(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMachineDeactivateMachine(id: string, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.putApiMachineDeactivateMachine(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MachineType2} machineType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMachineUpdate(id: string, machineType2: MachineType2, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.putApiMachineUpdate(id, machineType2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {TypeTagType2} typeTagType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiTagTypeUpdate(id: string, typeTagType2: TypeTagType2, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.putApiTagTypeUpdate(id, typeTagType2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {TagType2} tagType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiTagUpdate(id: string, tagType2: TagType2, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.putApiTagUpdate(id, tagType2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UserType2} userType2 update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiUserUpdate(id: string, userType2: UserType2, options?: any): AxiosPromise<ApiSuccessResponse> {
            return localVarFp.putApiUserUpdate(id, userType2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public afccfb0c51a5090b52093e646b29d9dc(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).afccfb0c51a5090b52093e646b29d9dc(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteApiEndpointRemove(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteApiEndpointRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteApiMachineRemove(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteApiMachineRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} idTag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteApiMachineTagRemove(id: string, idTag: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteApiMachineTagRemove(id, idTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteApiTagRemove(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteApiTagRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} idOwner 
     * @param {string} idTag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteApiTagRemoveChildrenTag(idOwner: string, idTag: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteApiTagRemoveChildrenTag(idOwner, idTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteApiTagRemoveType(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteApiTagRemoveType(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteApiTagTypeRemove(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteApiTagTypeRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteApiUserRemove(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteApiUserRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiEndpointAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiEndpointAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiInterventionsDefault(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiInterventionsDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiMachineAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiMachineAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiMachineSelectId(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiMachineSelectId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiTagAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiTagAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiTagSelectId(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiTagSelectId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiTagTypeAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiTagTypeAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiTagTypeSelectId(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiTagTypeSelectId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiUserAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiUserAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiUserLogged(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiUserLogged(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApiUserSelectId(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApiUserSelectId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EndpointType} endpointType create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiEndpointCreate(endpointType: EndpointType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiEndpointCreate(endpointType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MachineType} machineType create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiMachineCreate(machineType: MachineType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiMachineCreate(machineType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} idTag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiMachineTagAdd(id: string, idTag: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiMachineTagAdd(id, idTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ==================================================================== Interventions ====================================================================
     * @param {MetricsType} metricsType Interventions trend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiMetricsInterventions(metricsType: MetricsType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiMetricsInterventions(metricsType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ==================================================================== MTBF ====================================================================
     * @param {MetricsType} metricsType MTBF summary.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiMetricsMtbfSum(metricsType: MetricsType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiMetricsMtbfSum(metricsType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MetricsType} metricsType MTBF trend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiMetricsMtbfTrend(metricsType: MetricsType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiMetricsMtbfTrend(metricsType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ==================================================================== MTTR ====================================================================
     * @param {MetricsType} metricsType MTTR summary.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiMetricsMttrSum(metricsType: MetricsType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiMetricsMttrSum(metricsType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MetricsType} metricsType MTTR trend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiMetricsMttrTrend(metricsType: MetricsType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiMetricsMttrTrend(metricsType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ==================================================================== OEE ====================================================================
     * @param {MetricsType} metricsType Oee summary.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiMetricsOeeSum(metricsType: MetricsType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiMetricsOeeSum(metricsType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MetricsType} metricsType Oee trend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiMetricsOeeTrend(metricsType: MetricsType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiMetricsOeeTrend(metricsType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ==================================================================== TEEP ====================================================================
     * @param {MetricsType} metricsType Teep summary.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiMetricsTeepSum(metricsType: MetricsType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiMetricsTeepSum(metricsType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MetricsType} metricsType Teep trend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiMetricsTeepTrend(metricsType: MetricsType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiMetricsTeepTrend(metricsType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} idOwner 
     * @param {string} idTag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiTagAddChildrenTag(idOwner: string, idTag: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiTagAddChildrenTag(idOwner, idTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} idType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiTagAddType(id: string, idType: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiTagAddType(id, idType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagType} tagType create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiTagCreate(tagType: TagType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiTagCreate(tagType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TypeTagType} typeTagType create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiTagTypeCreate(typeTagType: TypeTagType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiTagTypeCreate(typeTagType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserType} userType create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiUserCreate(userType: UserType, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiUserCreate(userType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} idTag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putApiEndpointAddTag(id: string, idTag: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putApiEndpointAddTag(id, idTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {EndpointType2} endpointType2 update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putApiEndpointUpdate(id: string, endpointType2: EndpointType2, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putApiEndpointUpdate(id, endpointType2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} idGroup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putApiInterventionsSetGroup(id: string, idGroup: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putApiInterventionsSetGroup(id, idGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putApiMachineActivateMachine(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putApiMachineActivateMachine(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putApiMachineDeactivateMachine(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putApiMachineDeactivateMachine(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MachineType2} machineType2 update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putApiMachineUpdate(id: string, machineType2: MachineType2, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putApiMachineUpdate(id, machineType2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {TypeTagType2} typeTagType2 update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putApiTagTypeUpdate(id: string, typeTagType2: TypeTagType2, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putApiTagTypeUpdate(id, typeTagType2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {TagType2} tagType2 update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putApiTagUpdate(id: string, tagType2: TagType2, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putApiTagUpdate(id, tagType2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UserType2} userType2 update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putApiUserUpdate(id: string, userType2: UserType2, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putApiUserUpdate(id, userType2, options).then((request) => request(this.axios, this.basePath));
    }
}


