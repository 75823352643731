import React, {ReactNode} from 'react';
import {AuthProvider, AuthProviderProps} from "react-oidc-context";
import {useNavigate} from "react-router-dom";

interface AppProps {
  config: AuthProviderProps
  children?: ReactNode | undefined
}

export function AuthProviderWithRedirect({config, children}: AppProps) {
  const navigate = useNavigate();

  return <AuthProvider {...config} onSigninCallback={() => {
    const lastLocationString = localStorage.getItem('lastLocation');
    if (lastLocationString) {
      navigate(JSON.parse(lastLocationString))
    } else {
      navigate('')
    }
  }}>{children}</AuthProvider>
}
