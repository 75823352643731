import React, {useContext} from "react";
import {DataContext} from "../../context/DataContext";
import {Box, Typography} from "@mui/material";
import StringAvatar from "./../StringAvatar";
import {format} from "date-fns";
import csLocale from "date-fns/locale/cs";

interface HomepageHeaderProps {
  from: Date
  to: Date
}

export default function HomepageHeader({from, to}: HomepageHeaderProps) {
  const {user} = useContext(DataContext);

  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <StringAvatar size={68} fullName={user?.fullName || ''}/>
        <Box ml={2}>
          <Typography variant="h2">Dobrý den!</Typography>
          <Typography variant="caption" sx={{fontSize: 16, letterSpacing: 0}}>Dnes
            je {format(new Date(), 'eeee, d. LLLL Y', {locale: csLocale})}.</Typography>
        </Box>
      </Box>
      <Box mt={2}>
        <Typography
          variant="caption">Období {format(from, 'dd.MM.yyyy')} - {format(to, 'dd.MM.yyyy')}</Typography>
      </Box>
    </>
  )
}
