export const legendChangingColor = {
  angle: 90,
  keys: [
    {
      color: '#e65358',
      offset: 0.25
    }, {
      color: '#ffd06e',
      offset: 0.5
    }, {
      color: '#77b77a',
      offset: 0.75
    }],
  thickness: 3
}
