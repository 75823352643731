/// <reference types="anychart" />

import * as React from 'react';
import 'anychart';
import {createRef, useEffect, useRef} from "react";
import {DataShape} from "../../helpers/metric";
import {legendChangingColor} from "../../helpers/chart";

interface ParetoChartProps {
  values: DataShape[]
  otherValues: DataShape[]
  scaleValues?: string[] | number[]
  unit?: string
  colorSchema?: boolean
  maximum?: number
}

function coloringFunction() {
  // @ts-ignore
  if (this.value === undefined) return legendChangingColor as any;
  // @ts-ignore
  return this.value > 70 ? '#77b77a' : this.value > 50 ? '#ffd06e' : '#e65358'
}

export default function ParetoChart({values, otherValues, scaleValues, unit = '%', colorSchema = true, maximum}: ParetoChartProps) {
  const ref = createRef<HTMLDivElement>()
  const column = anychart.column();
  const chartRef = useRef(column);

  useEffect(() => {
    chartRef.current && chartRef.current.dispose();
    const x = anychart.column();
    chartRef.current = x;

    // turn on chart animation
    // chartRef.current.animation(true);

    // set chart title text settings
    chartRef.current.title('');


    // helper function to setup label settings for all series
    const setupSeries = function (series: anychart.core.cartesian.series.Column, name: string, fill?: string) {
      series.name(name);
      fill ? series.fill(fill).stroke(0) : series.fill(coloringFunction).stroke(0);
      series.selected().fill('#f48fb1 0.8').stroke('1.5 #c2185b');
    };

    const dataSetX = anychart.data.set(values);
    const dataSetY = anychart.data.set(otherValues);

    // create second series with mapped data
    const series = chartRef.current.column(dataSetX);
    series.xPointPosition(0.25);
    setupSeries(series, 'Vybrané období', colorSchema ? undefined : '#77b77a');

    // create first series with mapped data
    const secondSeries = chartRef.current.column(dataSetY);
    secondSeries.xPointPosition(0.45);
    setupSeries(secondSeries, 'Předchozí období', '#3b8ad9');

    // set chart padding
    chartRef.current.barGroupsPadding(0.3);

    // format numbers in y axis label to match browser locale
    // eslint-disable-next-line no-template-curly-in-string
    chartRef.current.yAxis().labels().format('{%Value}{groupsSeparator: }' + unit);

    // set titles for Y-axis
    chartRef.current.yAxis().title('');

    if (maximum) {
      chartRef.current.yScale().maximum(maximum);
    } else if (unit === '%') {
      chartRef.current.yScale().maximum(100);
    }

    // turn on legend
    chartRef.current.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0]);

    chartRef.current.interactivity().hoverMode('single');

    // eslint-disable-next-line no-template-curly-in-string
    chartRef.current.tooltip().format('{%Value}{groupsSeparator: }' + unit);

    scaleValues !== undefined && (chartRef.current.xScale() as unknown as anychart.scales.Ordinal).values(scaleValues)

    ref.current && chartRef.current.container(ref.current).draw();

    series.zIndex(secondSeries.zIndex()+1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current, values, scaleValues])

  return (
    <div ref={ref} style={{width: '100%', height: '100%'}} />
  );
}
