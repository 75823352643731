/// <reference types="anychart" />

import * as React from 'react';
import 'anychart';
import {createRef, useEffect, useRef} from "react";

interface GaugeChartProps {
  value: number
  invertColors: boolean
  showLabel?: boolean
  thicker?: boolean
}

export default function GaugeWithColorRangeChart({value, invertColors, showLabel = true, thicker = false}: GaugeChartProps) {
  const ref = createRef<HTMLDivElement>()
  var circularGauge = anychart.gauges.circular();
  const chartRef = useRef(circularGauge);

  useEffect(() => {
    const data = [value];
    const axisMaximum = 120;
    // data.push(axisMaximum);
    const dataSet = anychart.data.set(data);

    chartRef.current.data(dataSet);
    chartRef.current.fill('#fff')
      .stroke(0)
      .padding(0)
      .startAngle(270)
      .sweepAngle(180);

    // // enable pointer animation
    // animateGauge(chartRef.current);

    if(!showLabel) {
      chartRef.current.tooltip().enabled(false);
    } else {
      chartRef.current.tooltip().format('Hodnota {%value} min')
    }

    var circularAxis = chartRef.current.axis().radius(100).width(1).fill('transparent');
    circularAxis.scale().minimum(0);
    circularAxis.scale().maximum(axisMaximum);
    circularAxis.labels().enabled(false);
    circularAxis.ticks().enabled(false);
    circularAxis.minorTicks().enabled(false);

    chartRef.current
      .needle()
      .enabled(true)
      .stroke('2 #545f69')
      .startRadius('20%')
      .endRadius('90%')
      .startWidth('0.1%')
      .endWidth('0.1%')
      .middleWidth('0.1%');

    chartRef.current.range(0, {
      from: 0,
      to: axisMaximum,
      position: 'inside',
      fill: invertColors ? ['0.7 #77b77a', '.9 red'] : ['0.1 red', '.3 #77b77a'],
      startSize: 50,
      endSize: 50,
      radius: 98
    });

    if (showLabel) {
      const circularLabel0 = chartRef.current.label(0);
      circularLabel0
        .enabled(true)
        .anchor('center-bottom')
        .useHtml(true)
        .text(data[0]+' min')
        .fontColor('#555555')
        .offsetX('50%')
        .offsetY('-45%')
        .fontSize(20);
    }

    if (thicker) {
      chartRef.current.bounds('0%', '0%', '100%', '100%');
    } else {
      chartRef.current.bounds('25%','25%', '50%', '50%');
    }

    ref.current && chartRef.current.container(ref.current).draw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current, value])

  return (
    <div ref={ref} style={{width: '100%', height: '100%'}} />
  );
}
