import React, {useEffect, useState} from "react";
import {Box, Card, CardContent, Container, Grid, Typography} from "@mui/material";
import GaugeChart from "./charts/GaugeChart";
import ParetoChart from "./charts/ParetoChart";
import {
  Range,
  getScaleValues,
} from "../helpers/date";
import {roundToDecimalPlaces} from "../helpers/number";
import {
  isPercentMetric,
  Metric,
  sumEndpointFromMetric,
  trendEndpointFromMetric,
  useMetricSum,
  useMetricTrend
} from "../data/metric";
import GaugeWithColorRangeChart from "./charts/GaugeWithColorRangeChart";
import Helper from "./Helper";

interface MetricChartProps {
  range: Range
  from: Date
  to: Date
  tags: number[]
  metric: Metric,
  titleExtra?: string
}

export default function MetricChart({range, from, to, tags, metric, titleExtra}: MetricChartProps) {
  const sumEndpoint = sumEndpointFromMetric(metric);
  const trendEndpoint = trendEndpointFromMetric(metric);
  const data = useMetricSum(from, to, range, tags, sumEndpoint);
  const trendData = useMetricTrend(from, to, range, tags, trendEndpoint, sumEndpoint);
  const trendDataLastYear = useMetricTrend(from, to, range, tags, trendEndpoint, sumEndpoint, 'lastYear');

  const value = data?.data || 0;

  const [globalMaximum, setGlobalMaximum] = useState(0);

  useEffect(() => {
    setGlobalMaximum(0);
  }, [tags, range, from, to])

  if (!isPercentMetric(metric)) {
    trendData.forEach(item => item[1] > globalMaximum && setGlobalMaximum(currentMaximum => Math.max(currentMaximum, item[1])));
    trendDataLastYear.forEach(item => item[1] > globalMaximum && setGlobalMaximum(currentMaximum => Math.max(currentMaximum, item[1])));
  }

  if (value === 0 && trendData.length === 0 && trendDataLastYear.length === 0) {
    return (
      <Box mt={2}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent sx={{padding: 3}}>
                  {metric.toUpperCase()} {titleExtra} žádná data
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card>
              <CardContent sx={{padding: 4, position: 'relative'}}>
                <Typography gutterBottom variant="h3" component="div">
                  {metric.toUpperCase()} {titleExtra}
                  <Helper metric={metric} />
                </Typography>
                <Box height={300}>
                  {
                    isPercentMetric(metric) ?
                      <GaugeChart value={roundToDecimalPlaces(value)}/>
                      :
                      <GaugeWithColorRangeChart value={roundToDecimalPlaces(value)} invertColors={metric === 'mttr'}/>
                  }
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Card>
              <CardContent sx={{padding: 4}}>
                <Typography gutterBottom variant="h3" component="div">
                  Trendový {metric.toUpperCase()} {titleExtra}
                </Typography>
                <Box height={300}>
                  {
                    isPercentMetric(metric) ?
                      <ParetoChart values={trendData} otherValues={trendDataLastYear}
                                   scaleValues={getScaleValues(range, [...trendData, ...trendDataLastYear])}/>
                      :
                      <ParetoChart unit="min" values={trendData} otherValues={trendDataLastYear} colorSchema={false}
                                   scaleValues={getScaleValues(range, [...trendData, ...trendDataLastYear])}
                                   maximum={isPercentMetric(metric) ? undefined : globalMaximum}/>
                  }
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
