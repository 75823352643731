import React from "react";
import {Range} from "../helpers/date";
import {Tag} from "../openapi";
import MetricChart from "./MetricChart";
import {Metric} from "../data/metric";

export interface MetricBaseChartProps {
  tags: number[]
  range: Range
  from: Date
  to: Date
  groups: Tag[][]
}

export default function MetricBaseChart({groups, tags, range, from, to, metric}: MetricBaseChartProps & {metric: Metric}) {
  return (
    <>
      {
        groups.length === 0 && <MetricChart tags={tags} range={range} from={from} to={to} metric={metric}/>
      }
      {
        groups.map(group => {
          return <MetricChart tags={group.map(tag => tag.id) as number[]} range={range} from={from} to={to}
                              titleExtra={group.map(tag => tag.name).join(', ')} metric={metric}/>
        })
      }
    </>
  );
}
