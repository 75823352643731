import React from "react";
import {Box, Card, CardContent, Typography} from "@mui/material";
import {Tag} from "../../openapi";
import GaugeChart from "../charts/GaugeChart";
import GaugeWithColorRangeChart from "../charts/GaugeWithColorRangeChart";
import {isPercentMetric, Metric} from "../../data/metric";
import Helper from "../Helper";

interface HomepageDataRowBlockProps {
  sumValue: number
  values: Array<{ value: number, tag: Tag }>
  metric: Metric
}

export default function HomepageDataRowBlock({sumValue, values, metric}: HomepageDataRowBlockProps) {
  const unit = isPercentMetric(metric) ? ' %' : ' min';

  return (
    <>
      <Card>
        <CardContent sx={{padding: 4, position: 'relative'}}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
            <Box height={120} width={120}>
              {
                isPercentMetric(metric) ?
                  <GaugeChart value={sumValue} showLabel={false} thicker={true}/>
                  :
                  <GaugeWithColorRangeChart value={sumValue} showLabel={false} invertColors={metric === 'mttr'}
                                            thicker={true}/>
              }
            </Box>
            <Box>
              <Typography variant="h2"
                          sx={{marginLeft: 2, color: '#9EB8C6', fontSize: 36}}>{sumValue}<small
                style={{fontSize: 18}}>{unit}</small></Typography>
              <Typography variant="h2" sx={{marginLeft: 2, fontSize: 30}}>
                {metric.toUpperCase()}
                <Helper metric={metric} />
              </Typography>
            </Box>
          </Box>
          <Typography variant="h3" component="div" mt={2}>
            Celkové {metric.toUpperCase()} všech divizí
          </Typography>
        </CardContent>
      </Card>
      {
        values.map(({value, tag}) => {
          return (
            <Card sx={{mt: 2}}>
              <CardContent sx={{padding: 2}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 1}}>
                  <Box height={60} width={60}>
                    {
                      isPercentMetric(metric) ?
                        <GaugeChart value={value} showLabel={false} thicker={true}/>
                        :
                        <GaugeWithColorRangeChart value={value} showLabel={false} invertColors={metric === 'mttr'}
                                                  thicker={true}/>
                    }
                  </Box>
                  <Box>
                    <Typography variant="h2"
                                sx={{marginLeft: 3, color: '#9EB8C6', fontSize: 26}}>{value}{unit}</Typography>
                    <Typography variant="h2"
                                sx={{marginLeft: 3, fontSize: 18}}>{metric.toUpperCase()} {tag.name}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          )
        })
      }
    </>
  )
}
