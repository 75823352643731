import React from 'react';
import Routes from "./Routes";
import {BrowserRouter} from "react-router-dom";
import {AuthProviderProps} from "react-oidc-context";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers';
import csLocale from 'date-fns/locale/cs';
import {ColorModeContextProvider} from "./context/ColorModeContext";
import {ApiContextProvider} from "./context/ApiContext";
import {AppConfig, AppConfigContext} from "./config";
import {AuthProviderWithRedirect} from "./AuthProviderWithRedirect";
import {DataContextProvider} from "./context/DataContext";
import TopBar from "./components/TopBar";
import {Box} from "@mui/material";

interface AppProps {
  config: AppConfig
}

function App({config}: AppProps) {
  const oidcConfig: AuthProviderProps = {
    authority: config.REACT_APP_BACKEND_URI || '',
    metadata: {
      authorization_endpoint: `${config.REACT_APP_BACKEND_URI}/authorize`,
      token_endpoint: `${config.REACT_APP_BACKEND_URI}/token`,
    },
    scope: 'OEE_APP',
    client_id: config.REACT_APP_CLIENT_ID || '',
    client_secret: config.REACT_APP_CLIENT_SECRET || '',
    redirect_uri: `${window.location.protocol}//${window.location.host}/return`,
  };

  return (
    <AppConfigContext.Provider value={config}>
      <BrowserRouter>
        <AuthProviderWithRedirect config={oidcConfig}>
          <ApiContextProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={csLocale}>
              <ColorModeContextProvider>
                <DataContextProvider>
                  <Box sx={{
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    minHeight: 'calc(100vh - 64px)'
                  }}>
                    <TopBar/>
                    <Box mt={8}>
                      <Routes/>
                    </Box>
                  </Box>
                </DataContextProvider>
              </ColorModeContextProvider>
            </LocalizationProvider>
          </ApiContextProvider>
        </AuthProviderWithRedirect>
      </BrowserRouter>
    </AppConfigContext.Provider>
  );
}

export default App;
