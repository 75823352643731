import React from "react";
import {Range} from "../helpers/date";
import {Tag} from "../openapi";
import ParetoChart from "./ParetoChart";

export interface ParetoBaseChartProps {
  tags: number[]
  range: Range
  from: Date
  to: Date
  groups: Tag[][]
}

export default function ParetoBaseChart({tags, range, from, to, groups}: ParetoBaseChartProps) {
  return (
    <>
      {
        groups.length === 0 && <ParetoChart tags={tags} range={range} from={from} to={to} />
      }
      {
        groups.map(group => {
          return <ParetoChart tags={group.map(tag => tag.id) as number[]} range={range} from={from} to={to}
                              titleExtra={group.map(tag => tag.name).join(', ')} />
        })
      }
    </>
  );
}
