import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useContext} from "react";
import {ReactComponent as Logo} from '../logo.svg';
import {useAuth} from "react-oidc-context";
import {useNavigate, Link} from "react-router-dom";
import {DataContext} from "../context/DataContext";
import StringAvatar from "./StringAvatar";
import {ClickAwayListener, Paper, Popper} from "@mui/material";
import TopBarMetricLink from "./TopBarMetricLink";
import {AppConfigContext} from "../config";

const TopBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorElSubmenu, setAnchorElSubmenu] = React.useState<Map<number, HTMLElement | null>>(new Map());

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const auth = useAuth();
  const navigate = useNavigate();

  const {tagTypeAll: data, user} = useContext(DataContext);
  const {REACT_APP_BACKEND_URI} = useContext(AppConfigContext);

  const isAdmin = user?.roles?.includes('ROLE_ADMIN');

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/" style={{display: 'flex'}}><Logo style={{height: 50, cursor: 'pointer'}}/></Link>

          <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {xs: 'block', md: 'none'},
              }}
            >
              <MenuItem onClick={() => {
                handleCloseNavMenu();
                navigate('/')
              }}>
                <Typography textAlign="center">Domů</Typography>
              </MenuItem>
              {
                data?.[0]?.tags?.map(tag => {
                  return (
                    <MenuItem key={tag.id} onClick={() => {
                      handleCloseNavMenu();
                      navigate('/')
                    }}>
                      <Typography textAlign="center">{tag.name}</Typography>
                    </MenuItem>
                  )
                })
              }
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">Oblíbené</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{flexGrow: 1, justifyContent: 'flex-start', display: {xs: 'none', md: 'flex'}, ml: 3}}>
            {
              data?.find(tagType => tagType.name === 'division')?.tags?.map(tag => {
                return tag.id && (
                  <Box sx={{flexGrow: 0}} key={tag.id}>
                    <Button
                      onClick={(e) => {
                        tag.id && setAnchorElSubmenu(new Map(anchorElSubmenu.set(tag.id, e.currentTarget)))
                      }}
                      sx={{my: 2, color: 'white', display: 'block', ml: 3}}
                    >
                      {tag.name}
                    </Button>
                    <Popper
                      open={Boolean(anchorElSubmenu.get(tag.id))}
                      anchorEl={anchorElSubmenu.get(tag.id)}
                      sx={{zIndex: 10000}}
                      placement="bottom-start"
                    >
                      <ClickAwayListener
                        onClickAway={() => tag.id && Boolean(anchorElSubmenu.get(tag.id)) && setAnchorElSubmenu(new Map(anchorElSubmenu.set(tag.id, null)))}>
                        <Paper>
                          <Box p={2}>
                            <TopBarMetricLink
                              tags={tag.id.toString() || ''}
                              name="Celá divize"
                              onClick={() => tag.id && setAnchorElSubmenu(new Map(anchorElSubmenu.set(tag.id, null)))}
                            />
                            {
                              tag.myTags?.map(subtag => <TopBarMetricLink
                                tags={subtag.id?.toString() || ''}
                                name={subtag.name}
                                key={subtag.id}
                                onClick={() => tag.id && setAnchorElSubmenu(new Map(anchorElSubmenu.set(tag.id, null)))}
                              />)
                            }
                            {
                              (tag.myTags?.length || 0) > 1 && <TopBarMetricLink
                                    tags={tag.myTags?.map(tag => tag.id).join(',') || ''}
                                    name="Všechny firmy divize"
                                    onClick={() => tag.id && setAnchorElSubmenu(new Map(anchorElSubmenu.set(tag.id, null)))}
                                />
                            }
                          </Box>
                        </Paper>
                      </ClickAwayListener>
                    </Popper>
                  </Box>
                )
              })
            }
            {/*<Button*/}
            {/*  onClick={handleCloseNavMenu}*/}
            {/*  sx={{my: 2, color: 'white', display: 'block'}}*/}
            {/*>*/}
            {/*  Oblíbené*/}
            {/*</Button>*/}
          </Box>

          <Box sx={{flexGrow: 0}}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                <StringAvatar fullName={user?.fullName || ''}/>
              </IconButton>
            </Tooltip>
            <Popper
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              placement="bottom-end"
              sx={{zIndex: 10000}}
            >
              <ClickAwayListener onClickAway={handleCloseUserMenu}>
                <Paper sx={{mt: 1, py: 1}}>
                  {
                    isAdmin && <MenuItem component={Link} to="/machineConfiguration" onClick={handleCloseUserMenu}>
                          <Typography textAlign="center">Konfigurace strojů</Typography>
                      </MenuItem>
                  }
                  {
                    isAdmin && <MenuItem component={Link} to="/interventionGroups" onClick={handleCloseUserMenu}>
                          <Typography textAlign="center">Konfigurace skupin prostojů</Typography>
                      </MenuItem>
                  }
                  {
                    isAdmin && <MenuItem component={Link} to="/interventionGroupAssign" onClick={handleCloseUserMenu}>
                          <Typography textAlign="center">Přiřazení skupin prostojů</Typography>
                      </MenuItem>
                  }
                  {
                    isAdmin && <MenuItem component={Link} to="/users" onClick={handleCloseUserMenu}>
                          <Typography textAlign="center">Uživatelé</Typography>
                      </MenuItem>
                  }
                  <MenuItem onClick={() => {
                    handleCloseUserMenu();
                    auth.removeUser();
                    window.location.href = `${REACT_APP_BACKEND_URI}/logout`;
                  }}>
                    <Typography textAlign="center">Odhlásit</Typography>
                  </MenuItem>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default TopBar;
