import * as React from 'react';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {ReactNode} from "react";

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

interface ColorModeContextProviderProps {
  children?: ReactNode | undefined
}

export function ColorModeContextProvider({children}: ColorModeContextProviderProps) {
  const [mode, setMode] = React.useState<'light' | 'dark'>('light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#003A70'
          },
          background: {
            default: '#F1F6FA'
          },
        },
        shape: {
          borderRadius: 15
        },
        typography: {
          fontFamily: '"industry","Roboto","Helvetica","Arial",sans-serif',
          caption: {
            color: '#9EB8C6'
          },
          h2: {
            color: "#003A70",
            fontSize: 26,
            fontWeight: 700
          },
          h3: {
            color: "#003A70",
            fontSize: 20,
            fontWeight: 700
          },
          h4: {
            color: "#003A70",
            fontSize: 16,
            fontWeight: 700
          },
          body2: {
            fontSize: 16,
            color: '#003A70',
            textTransform: 'none'
          }
        },
        components: {
          MuiInputLabel: {
            styleOverrides: {
              root: {
                background: '#ffffff'
              }
            }
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                background: '#ffffff'
              }
            }
          },
          MuiToggleButton: {
            styleOverrides: {
              root: ({ownerState}) => ({
                padding: 22,
                backgroundColor: ownerState.selected ? '#D6E4EC !important' : '#ffffff !important'
              })
            }
          },
          MuiSwitch: {
            styleOverrides: {
              switchBase: {
                color: '#4CAF50 !important'
              },
              track: {
                opacity: '0.38 !important',
                backgroundColor: '#81C784 !important'
              }
            }
          }
        },
      }),
    [mode],
  );

  // React.useEffect(() => {
  //   mode === 'dark' ? anychart.theme(anychartLightTheme) : anychart.theme(anychartDarkTheme);
  // }, [mode])

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
