import React from 'react';

export interface AppConfig {
  REACT_APP_BACKEND_URI: string;
  REACT_APP_CLIENT_ID: string;
  REACT_APP_CLIENT_SECRET: string;
}


export function loadAppConfig(): Promise<AppConfig> {
  return fetch('/config.json')
    .then(response => response.json());
}

export const AppConfigContext = React.createContext<AppConfig>({} as AppConfig);
