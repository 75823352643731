import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, {useContext, useEffect, useState} from "react";
import {ApiContext} from "../context/ApiContext";
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from "@mui/material/AccordionDetails";
import {Machine, Tag} from "../openapi";
import {Grid} from "@mui/material";
import SingleAutocompleteField from "../components/inputs/SingleAutocompleteField";
import {DataContext} from "../context/DataContext";

export default function MachinesConfiguration() {
  const {api} = useContext(ApiContext);
  const [machines, setMachines] = useState<Machine[]>([]);

  const {tagAll: tags, tagTypeAll: tagTypes, reload: reloadData} = useContext(DataContext)

  useEffect(() => {
    api.getApiMachineAll().then(({data}) => setMachines(data));
  }, [api])

  const updateMachineTags = (machine: Machine, newTags: Tag[]) => {
    const addedTags = newTags.filter(newTag => !machine.tags?.some(tag => tag.id === newTag.id));
    const removedTags = machine?.tags?.filter(machineTag => !newTags.some(tag => tag.id === machineTag.id)) || [];

    const promises = [];
    for (const addedTag of addedTags) {
      promises.push(api.postApiMachineTagAdd(machine.id as unknown as string, addedTag.id as unknown as string));
    }
    for (const removedTag of removedTags) {
      promises.push(api.deleteApiMachineTagRemove(machine.id as unknown as string, removedTag.id as unknown as string));
    }
    Promise.all(promises).then(() => {
      api.getApiMachineAll().then(({data}) => {setMachines(data); reloadData();}); // TODO: reload only single machine?
    })
  }

  return (
    <div style={{padding: 20}}>
      {
        machines.map(machine => (<Accordion key={machine.id} disabled={!machine.active}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
          >
            <Typography sx={{width: '20%', flexShrink: 0}}><b>{machine.internalNumber}</b> {machine.name}</Typography>
            <Typography sx={{width: '20%', color: 'text.secondary'}}>{machine.description}</Typography>
            <Typography sx={{color: 'text.secondary'}}>{machine.tags?.map(tag => tag.name)?.join(', ')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {
                tagTypes?.filter(tagType => tagType.name === 'company' || tagType.name === 'technology').map((tagType) => <Grid item xs={3}
                                                                                                key={tagType.id}>
                    {tagType.tags &&
                        <SingleAutocompleteField
                            label={tagType.description || ''}
                            tags={tagType.tags}
                            values={machine.tags?.map(tag => tag.id).filter(tagId => tagId !== undefined) as number[]}
                            setValues={(tagIds) => {
                              updateMachineTags(machine, tagIds.map(tagId => tags?.find(tag => tag.id === tagId)) as Tag[]);
                            }}
                        />
                    }
                  </Grid>
                )}
            </Grid>
          </AccordionDetails>
        </Accordion>))
      }
    </div>
  );
}
