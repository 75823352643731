import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'querystring-es3';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {loadAppConfig} from "./config";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


loadAppConfig().then(config => {
  root.render(
    // <React.StrictMode>
    <App config={config} />
    // </React.StrictMode>
  );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
