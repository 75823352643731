import React, {useCallback, useContext, useEffect, useState} from "react";
import {
  Box,
  Paper, Select, Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tabs
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import {DataContext} from "../context/DataContext";
import {ApiContext} from "../context/ApiContext";
import {ApiSuccessResponse, InterventionGroupDto, Machine, Tag} from "../openapi";
import {addOrRemove} from "../helpers/array";
import { AxiosResponse } from 'axios';

function Row({title, machine, checked, onChange, groupTag, company}: { title: string, company: string, machine: string, checked: boolean, onChange: () => void, groupTag: string }) {
  return (
    <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={checked}
          onChange={onChange}
        />
      </TableCell>
      <TableCell component="th" scope="row">{title}</TableCell>
      <TableCell component="th" scope="row">{machine}</TableCell>
      <TableCell component="th" scope="row">{company}</TableCell>
      <TableCell component="th" scope="row">{groupTag}</TableCell>
    </TableRow>
  );
}

export default function InterventionGroupAssign() {
  const [tabIndex, setTabIndex] = useState(0);
  const {tagTypeAll} = useContext(DataContext);
  const tags = tagTypeAll.find(type => type.name === 'intervention-generic')?.tags || [] as Tag[]

  const {api} = useContext(ApiContext);
  const [interventions, setInterventions] = useState<InterventionGroupDto[]>([]);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedTagId, setSelectedTagId] = useState<string>(tags?.[0]?.id?.toString() || '');
  useEffect(() => {
    api.getApiInterventionsDefault().then(({data}) => setInterventions(data));
    api.getApiMachineAll().then(({data}) => setMachines(data));
  }, [api, setInterventions, setMachines])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    setSelectedValues([]);
  };

  const assignGroup = useCallback(() => {
    const promises: Promise<AxiosResponse<ApiSuccessResponse>>[] = [];
    selectedValues.forEach(selectedValue => {
      promises.push(api.putApiInterventionsSetGroup(selectedValue, selectedTagId))
    })
    Promise.all(promises).then(() => {
      api.getApiInterventionsDefault().then(({data}) => setInterventions(data));
    })
    setSelectedValues([]);
    window.scrollTo({top: 0})
  }, [api, selectedTagId, selectedValues])

  return (
    <div style={{padding: 24}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 2}}>
        <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label="Obecné prostoje"/>
          <Tab label="Specifické prostoje"/>
        </Tabs>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
              </TableCell>
              <TableCell>Název</TableCell>
              <TableCell>Stroj</TableCell>
              <TableCell>Společnost</TableCell>
              <TableCell>Skupina</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              interventions.filter(intervention => {
                if (tabIndex === 0) {
                  return intervention.ownerMachine === null
                } else {
                  return intervention.ownerMachine !== null
                }
              }).map(intervention => <Row
                key={intervention.id}
                title={intervention.name || ''}
                company={intervention.companyName || ''}
                machine={machines.find(machine => machine.id === intervention.ownerMachine)?.name || '-'}
                checked={selectedValues.includes(intervention.id?.toString() || '')}
                onChange={() => setSelectedValues(selectedValues => addOrRemove(selectedValues, intervention.id?.toString() || ''))}
                groupTag={intervention.genericInterventionGroupName || '-'}
              />)
            }
          </TableBody>
        </Table>
      </TableContainer>

      <Box pt={2}>
        <Select value={selectedTagId} onChange={(e) => setSelectedTagId(e.target.value as string)}>
          {
            tags.map(tag => <MenuItem key={tag.id} value={tag.id?.toString() || ''}>{tag.name}</MenuItem>)
          }
        </Select>
        <Button variant="contained" size="large" sx={{marginLeft: 2}} onClick={assignGroup}>Přiřadit skupinu vybraným prostojům</Button>
      </Box>
    </div>
  );
}
