import React, {useContext} from "react";
import {DataContext} from "../context/DataContext";
import {useMetricSumBySingleTag} from "../data/metric";
import {Range} from "../helpers/date";
import {Tag} from "../openapi";
import {Box, Card, CardContent, Typography} from "@mui/material";
import GaugeChart from "./charts/GaugeChart";
import Helper from "./Helper";

interface TopCompaniesChartProps {
  from: Date
  to: Date
}

export default function TopCompaniesChart({from, to}: TopCompaniesChartProps) {
  const {tagTypeAll} = useContext(DataContext);
  const companyTags = tagTypeAll?.find(tagType => tagType.name === 'company')?.tags || [];
  const dataByTag = useMetricSumBySingleTag(from, to, Range.Week, companyTags?.map(tag => tag.id || 0), 'postApiMetricsOeeSum');
  const result = companyTags.map((tag: Tag, index) => {
    return {tag, value: dataByTag?.[index] || 0};
  })
  const sortedResult = result.sort((a, b) => {
    if (a.value > b.value) {
      return -1
    } else if (b.value > a.value) {
      return 1
    } else {
      return 0;
    }
  });

  return (
    <Card sx={{height: '100%'}}>
      <CardContent sx={{padding: 4, position: 'relative'}}>
        <Typography gutterBottom variant="h3" component="div">
          TOP 5 firem podle OEE
          <Helper metric="oee" />
        </Typography>
        {
          sortedResult.slice(0, 5).map(({tag, value}) => {
            return (
              <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 1}}>
                <Box height={40} width={40}>
                  <GaugeChart value={value} showLabel={false} thicker={true}/>
                </Box>
                <Typography variant="h2" sx={{marginX: 3, color: '#9EB8C6', width: 85}}>{value}%</Typography>
                <Typography variant="h3" sx={{fontSize: 16}}>{tag.name}</Typography>
              </Box>
            )
          })
        }
      </CardContent>
    </Card>
  );
}
