import React, {useState} from "react";
import {Box, Button, Modal, TextField} from "@mui/material";
import {Tag} from "../../openapi";
import {TwitterPicker} from 'react-color'

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 340,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

interface TagFormModalProps {
  isOpen: boolean,
  handleClose: () => void
  tag: Tag | null,
  onSave: (name: string, description: string, color: string) => void
}

export default function TagFormModal({isOpen, handleClose, tag, onSave}: TagFormModalProps) {
  const [name, setName] = useState(tag?.name || '');
  const [description, setDescription] = useState(tag?.description || '');
  const [color, setColor] = useState(tag?.color || '#003A70');

  const nameError = name.length < 2;
  const descriptionError = description.length < 2;
  const colorError = (color.length < 4 || !color.startsWith('#')) && color.length !== 0;

  const hasAnyError = nameError || descriptionError || colorError;

  const [nameTouched, setNameTouched] = useState(false);
  const [descriptionTouched, setDescriptionTouched] = useState(false);

  const setAllTouched = () => {
    setNameTouched(true);
    setDescriptionTouched(true);
  };

  return (
    <Modal open={isOpen}>
      <Box sx={modalStyle}>
        <Box py={1}>
          <TextField fullWidth label="Název" value={name} onChange={e => {
            setName(e.target.value);
            setNameTouched(true)
          }} error={nameError && nameTouched}/>
        </Box>
        <Box py={1}>
          <TextField fullWidth label="Popis" value={description} onChange={e => {
            setDescription(e.target.value);
            setDescriptionTouched(true)
          }} error={descriptionError && descriptionTouched}/>
        </Box>
        <Box py={1}>
          <TwitterPicker color={color} triangle="hide" colors={['#4D93D1', '#9C4B94', '#E1E1E1', '#002A5C', '#FFD06E', '#E65358', '#E69BB7', '#5B3030', '#626262', '#FF886A']} onChange={value => {
            setColor(value.hex);
          }} />
        </Box>
        <Box py={1} sx={{textAlign: 'right'}}>
          <Button variant="outlined" size="large" sx={{mr: 1}} onClick={() => handleClose()}>
            Zrušit
          </Button>
          <span onClick={() => setAllTouched()}>
            <Button variant="contained" size="large" onClick={() => onSave(name, description, color)} disabled={hasAnyError}>
              Uložit
            </Button>
          </span>
        </Box>
      </Box>
    </Modal>
  )
}
