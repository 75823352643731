import {DefaultApi, GraphSummaryResponse, GraphTrendResponse} from "../openapi";
import {
  dateStringToKey,
  formatDateForBackend, metricFilterForSpecialDates,
  Range,
  rangeToElasticPeriod,
  trendFrom,
  trendLastYearFrom, trendLastYearTo,
  trendTo
} from "./date";
import {roundToDecimalPlaces} from "./number";
import {AxiosResponse} from 'axios';

export type DataShape = [string, number, string?, string?]

export function formatMetricTrendDataForChart(data: GraphTrendResponse, range: Range): DataShape[] {
  try {
    return data?.data?.map(item => [dateStringToKey((item as any).start, range), roundToDecimalPlaces((item as any).value)]) || []
  } catch (e) {
    return [];
  }
}


// tmp function to use sum endpoint to get trend for 3m and 6m, since old elastic cant handle that
export function getTrendDataSum(api: DefaultApi, sumEndpoint: 'postApiMetricsOeeSum' | 'postApiMetricsTeepSum' | 'postApiMetricsMttrSum' | 'postApiMetricsMtbfSum', date: Date, endDate: Date, range: Range.Months3 | Range.Months6, tags: number[], setData: (data: DataShape[]) => void) {
  const promises: Promise<AxiosResponse<GraphSummaryResponse, any>>[] = [];
  const filters = metricFilterForSpecialDates(date, endDate, range, tags);
  for (const filter of filters) {
    promises.push(api[sumEndpoint](filter))
  }

  Promise.all(promises).then((response) => {
    const trendData: string[][] = []
    response.forEach((item) => {
      trendData.push({start: item.data.intervalStart, value: item.data.data} as unknown as string[]);
    })
    setData(formatMetricTrendDataForChart({data: trendData}, range));
  });
}

export function sumFilter(from: Date, to: Date, range: Range, tags: number[]) {
  return {
    intervalStart: formatDateForBackend(from),
    intervalEnd: formatDateForBackend(to),
    period: rangeToElasticPeriod(range),
    tags: tags.join(',')
  }
}

export function trendFilter(from: Date, to: Date, range: Range, tags: number[]) {
  return {
    intervalStart: formatDateForBackend(trendFrom(from, to, range)),
    intervalEnd: formatDateForBackend(trendTo(from, to, range)),
    period: rangeToElasticPeriod(range),
    tags: tags.join(',')
  }
}

export function trendLastYearFilter(from: Date, to: Date, range: Range, tags: number[]) {
  return {
    intervalStart: formatDateForBackend(trendLastYearFrom(from, to, range)),
    intervalEnd: formatDateForBackend(trendLastYearTo(from, to, range)),
    period: rangeToElasticPeriod(range),
    tags: tags.join(',')
  }
}
