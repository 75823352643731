import React from "react";
import {
  Routes as RouterRoutes,
  Route, useLocation,
} from "react-router-dom";
import Homepage from "./pages/Homepage";
import TestPage from "./pages/TestPage";
import {useAuth} from "react-oidc-context";
import MachinesConfiguration from "./pages/MachinesConfiguration";
import InterventionGroups from "./pages/InterventionGroups";
import InterventionGroupAssign from "./pages/InterventionGroupAssign";
import DataRoutes from "./pages/DataRoutes";
import Users from "./pages/Users";
import TagEdit from "./pages/TagEdit";

export default function Routes() {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.isLoading && !auth.isAuthenticated) {
    localStorage.setItem('lastLocation', JSON.stringify(location));
    auth.signinRedirect();
  }

  auth.startSilentRenew();

  if (!auth.user?.access_token) {
    return null;// unauthorized
  }

  return (
      <RouterRoutes>
        <Route path="/" element={<Homepage/>} />
        <Route path="/data/*" element={<DataRoutes/>} />
        <Route path="/machineConfiguration" element={<MachinesConfiguration/>} />
        <Route path="/interventionGroups" element={<InterventionGroups/>} />
        <Route path="/interventionGroupAssign" element={<InterventionGroupAssign/>} />
        <Route path="/users" element={<Users/>} />
        <Route path="/test" element={<TestPage/>} />
        <Route path="/edit" element={<TagEdit/>} />
      </RouterRoutes>
  );
}
