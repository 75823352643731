import {useContext, useEffect, useState} from "react";
import {ApiContext} from "../context/ApiContext";
import {sumFilter,} from "../helpers/metric";
import {formatDateForBackend, metricFilterForSpecialDates, Range} from "../helpers/date";
import {InterventionsTrendResponse} from "../openapi";

interface TrendDataValues {
  groupTagName: string
  groupTagId: number
  hours: number
  seconds: number
}

export interface TrendData {
  startDate: string
  values: TrendDataValues[]
}

const formatInterventionsTrend = ([date, values]: [date: string, values: any]) => ({
  startDate: date,
  values: values.map((value: any) => ({
    groupTagName: value['generic-group-name'],
    groupTagId: value['generic-group-id'],
    hours: value['summary-hours'],
    seconds: value['summary-seconds'],
  }))
})

const formatResponse = (data: InterventionsTrendResponse): TrendData[] => {
  try {
    return Object.entries(data?.data as any).map(formatInterventionsTrend)
  } catch (e) {
    return []
  }
}

export const mergeTrendData = (data: TrendDataValues[][]): TrendDataValues[] => {
  const finalData: TrendDataValues[] = [];

  data.forEach(intervalData => {
    intervalData.forEach(groupData => {
      const existingData = finalData.find(item => item.groupTagId === groupData.groupTagId);
      if (!existingData) {
        finalData.push({...groupData})
      } else {
        existingData.hours += groupData.hours;
        existingData.seconds += groupData.seconds;
      }
    })
  })

  return finalData;
}

export const useInterventionData = (from: Date, to: Date, range: Range, tags: number[]) => {
  const {api} = useContext(ApiContext);
  const [data, setData] = useState<TrendData[]>();

  const fromFormatted = formatDateForBackend(from);
  const toFormatted = formatDateForBackend(to);
  const tagsFormatted = tags.join(';');

  useEffect(() => {
    if (range === Range.Months3 || range === Range.Months6) {
      const filters = metricFilterForSpecialDates(from, to, range, tags);
      const promises = [];
      for (const filter of filters) {
        promises.push(api.postApiMetricsInterventions(filter))
      }
      Promise.all(promises).then(([...data]) => {
        const mappedResponse = data.map(item => formatResponse(item.data));
        const mergedData: TrendData[] = mappedResponse
          .map((values, index) => ({values, filter: filters[index]}))
          .map(({values, filter}) => {
            return {
              startDate: filter.intervalStart,
              values: mergeTrendData(values.map(({values}) => values))
            }
          });
        setData(mergedData);
      })
    } else {
      api.postApiMetricsInterventions(sumFilter(from, to, range, tags)).then(({data}) => {
        setData(formatResponse(data))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, fromFormatted, toFormatted, api, tagsFormatted]);

  return data;
}
