import React from "react";
import SideBar from "../components/SideBar";
import {Box, Container, Grid} from "@mui/material";
import {add, endOfMonth, previousSunday, startOfYear, sub} from "date-fns";
import Divider from "@mui/material/Divider";
import TopCompaniesChart from "../components/TopCompaniesChart";
import HomepageHeader from "../components/homepage/HomepageHeader";
import HomepageMetricBlock from "../components/homepage/HomepageMetricBlock";
import OeeTrendChart from "../components/OeeTrendChart";
import ReasonsChart from "../components/ReasonsChart";

export default function Homepage() {
  const fromTrend = startOfYear(sub(new Date(), {months: 1}));
  const toTrend = endOfMonth(sub(new Date(), {months: 1}));
  const fromWeek = add(sub(previousSunday(new Date()), {weeks: 1}), {days: 1});
  const toWeek = previousSunday(new Date());

  return (
    <Box sx={{display: 'flex'}}>
      <SideBar/>
      <Box component="main" sx={{flexGrow: 1, p: 3, pt: 3, overflow: 'auto', height: 'calc(100vh - 64px)'}}>
        <Container maxWidth="xl">
          <HomepageHeader from={fromWeek} to={toWeek}/>
          <Grid container spacing={2} mt={1}>
            <Grid item lg={3.5} xs={12}>
              <TopCompaniesChart from={fromWeek} to={toWeek}/>
            </Grid>
            <Grid item lg={3.5} xs={12}>
              <ReasonsChart from={fromWeek} to={toWeek}/>
            </Grid>
            <Grid item lg={5} xs={12}>
              <OeeTrendChart from={fromTrend} to={toTrend}/>
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{my: 4, mx: 0}}/>
          <Grid container spacing={2}>
            <Grid item lg={3} xs={12}>
              <HomepageMetricBlock from={fromWeek} to={toWeek} metric="oee"/>
            </Grid>
            <Grid item lg={3} xs={12}>
              <HomepageMetricBlock from={fromWeek} to={toWeek} metric="teep"/>
            </Grid>
            <Grid item lg={3} xs={12}>
              <HomepageMetricBlock from={fromWeek} to={toWeek} metric="mttr"/>
            </Grid>
            <Grid item lg={3} xs={12}>
              <HomepageMetricBlock from={fromWeek} to={toWeek} metric="mtbf"/>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
