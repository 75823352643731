import {useLocation, useSearchParams} from "react-router-dom";
import {add, format, parseISO, previousSunday, sub} from "date-fns";
import {useContext, useEffect, useMemo, useState} from "react";
import {Tag} from "./openapi";
import {Range} from './helpers/date';
import {DataContext} from "./context/DataContext";
import {cartesian} from "./helpers/array";

export interface TypeIdWithCount {
  typeId: number;
  count: number;
  tags: Tag[];
}

export const useFiltration = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const searchParamFrom = searchParams.get('from');
  const searchParamTo = searchParams.get('to');
  const searchParamTags = useMemo(() => searchParams.get('tags')?.split(',').map(item => parseInt(item)).filter(num => !isNaN(num)) || [], [searchParams]);
  const initialFrom = searchParamFrom !== null ? parseISO(searchParamFrom) : add(sub(previousSunday(new Date()), {weeks: 1}), {days: 1});
  const initialTo = searchParamTo !== null ? parseISO(searchParamTo) : previousSunday(new Date());

  const [range, setRange] = useState<Range>(searchParams.get('range') as Range || Range.Week);
  const [from, setFrom] = useState<Date>(initialFrom);
  const [to, setTo] = useState<Date>(initialTo);
  const [tags, setTags] = useState<number[]>(searchParamTags);

  useEffect(() => {
    setSearchParams({
      tags: tags.join(','),
      range,
      from: format(from, 'yyyy-MM-dd'),
      to: format(to, 'yyyy-MM-dd')
    })
  }, [tags, range, from, to, setSearchParams])

  useEffect(() => {
    if ((location?.state as any)?.force) {
      setTags(searchParamTags)
    }
  }, [searchParamTags, location])

  const {tagAll: tagData} = useContext(DataContext)

  const groups = useMemo(() => {
    const tagIdsByType = tags.reduce((acc: TypeIdWithCount[], tag) => {
      const tagEntity = tagData?.find(tagItem => tagItem.id === tag);
      if (tagEntity && tagEntity?.type?.id) {
        if (acc.some(item => item.typeId === tagEntity?.type?.id)) {
          const type = acc.find(item => item.typeId === tagEntity?.type?.id)
          type && type.count++;
          type && type.tags.push(tagEntity);
        } else {
          acc.push({typeId: tagEntity?.type?.id, count: 1, tags: [tagEntity]})
        }
      }
      return acc;
    }, [])

    const flatTags = tagIdsByType.map(item => item.tags);
    return cartesian(...flatTags)

  }, [tags, tagData])


  return {range, setRange, from, setFrom, to, setTo, tags, setTags, groups};
}
