export const addOrRemove = <T>(arr: T[], item: T) => arr.includes(item) ? arr.filter(i => i !== item) : [ ...arr, item ];

type MapCartesian<T extends any[][]> = {
  [P in keyof T]: T[P] extends Array<infer U>? U: never
}
export const cartesian = <T extends any[][]>(...arr: T): MapCartesian<T>[] =>
  arr.reduce(
    (a, b) => a.flatMap(c => b.map(d => [...c, d] )),
    [[]]
  ) as MapCartesian<T>[];
