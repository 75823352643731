import React, {useContext} from "react";
import {DataContext} from "../context/DataContext";
import EditableTagList from "../components/tag/EditableTagList";

export default function TagEdit() {
  const {tagTypeAll} = useContext(DataContext)
  const type = tagTypeAll.find(type => type.name === 'technology');

  if (!type) {
    return null;
  }

  return <EditableTagList tagType={type} />
}
