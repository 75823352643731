import * as React from 'react';
import {styled, Theme, CSSObject} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import OeeImage from '../assets/oee.svg'
import TeepImage from '../assets/teep.svg'
import MttrImage from '../assets/mttr.svg'
import MtbfImage from '../assets/mtbf.svg'
import ParetoImage from '../assets/pareto.svg'
import {useLocation, Link} from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  top: 69,
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  top: 69,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
  ({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const buttonStyle = {
  minHeight: 48,
  justifyContent: 'center',
  px: 2.5,
  flexDirection: 'column',
  height: 52,
  overflow: 'hidden',
  '&:hover': {'.MuiListItemText-root': {opacity: 1}},
};

export default function MiniDrawer() {
  const {search} = useLocation();

  return (
    <Drawer variant="permanent" open={false}>
      <Divider/>
      <List>
        <ListItem component={Link} to={`/data/oee${search}`} disablePadding sx={{display: 'block'}}>
          <ListItemButton sx={buttonStyle}>
            <img src={OeeImage} width={24} alt="Oee"/>
            <ListItemText primary="OEE" primaryTypographyProps={{fontSize: 11}} sx={{opacity: 0}}/>
          </ListItemButton>
        </ListItem>
        <ListItem component={Link} to={`/data/teep${search}`} disablePadding sx={{display: 'block'}}>
          <ListItemButton sx={buttonStyle}>
            <img src={TeepImage} width={24} alt="Teep"/>
            <ListItemText primary="TEEP" primaryTypographyProps={{fontSize: 11}} sx={{opacity: 0}}/>
          </ListItemButton>
        </ListItem>
        <ListItem component={Link} to={`/data/mttr${search}`} disablePadding sx={{display: 'block'}}>
          <ListItemButton sx={buttonStyle}>
            <img src={MttrImage} width={24} alt="Mttr"/>
            <ListItemText primary="MTTR" primaryTypographyProps={{fontSize: 11}} sx={{opacity: 0}}/>
          </ListItemButton>
        </ListItem>
        <ListItem component={Link} to={`/data/mtbf${search}`} disablePadding sx={{display: 'block'}}>
          <ListItemButton sx={buttonStyle}>
            <img src={MtbfImage} width={24} alt="Mtbf"/>
            <ListItemText primary="MTBF" primaryTypographyProps={{fontSize: 11}} sx={{opacity: 0}}/>
          </ListItemButton>
        </ListItem>
        <ListItem component={Link} to={`/data/pareto${search}`} disablePadding sx={{display: 'block'}}>
          <ListItemButton sx={buttonStyle}>
            <img src={ParetoImage} width={24} alt="Pareto"/>
            <ListItemText primary="Pareto" primaryTypographyProps={{fontSize: 11}} sx={{opacity: 0}}/>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
