import * as React from 'react';
import {ReactNode, useContext, useEffect, useState} from "react";
import {Configuration, DefaultApi} from "../openapi";
import {useAuth} from "react-oidc-context";
import {AppConfigContext} from "../config";

export const ApiContext = React.createContext({api: new DefaultApi()});

interface ApiContextProviderProps {
  children?: ReactNode | undefined
}

export function ApiContextProvider({children}: ApiContextProviderProps) {
  const auth = useAuth();
  const {REACT_APP_BACKEND_URI} = useContext(AppConfigContext);
  const [api, setApi] = useState<DefaultApi>(new DefaultApi());

  useEffect(() => {
    const configuration = new Configuration({
      basePath: REACT_APP_BACKEND_URI,
      accessToken: auth.user?.access_token,
      baseOptions: {headers: {'Authorization': `Bearer ${auth.user?.access_token}`}}
    });
    setApi(new DefaultApi(configuration));
  }, [REACT_APP_BACKEND_URI, auth.user?.access_token]);

  return (
    <ApiContext.Provider value={{api}}>
      {children}
    </ApiContext.Provider>
  );
}
