/// <reference types="anychart" />

import * as React from 'react';
import 'anychart';
import {createRef, useEffect, useRef} from "react";

interface ColumnChartProps {
  values: {
    x: string,
    value: number,
    name?: string
    fill?: string|null
  }[][]
  scaleValues?: string[] | number[]
  unit?: string
}

export default function ColumnChart({values, scaleValues, unit = '%'}: ColumnChartProps) {
  const ref = createRef<HTMLDivElement>()
  const column = anychart.column();
  const chartRef = useRef(column);

  useEffect(() => {
    chartRef.current && chartRef.current.dispose();
    const x = anychart.column();
    chartRef.current = x;

    // turn on chart animation
    // chartRef.current.animation(true);

    // set chart title text settings
    chartRef.current.title('');

    // temp variable to store series instance
    var series;

    // helper function to setup label settings for all series
    var setupSeries = function (series: anychart.core.cartesian.series.Column, name: string, fill?: string|null) {
      series.name(name);
      series.stroke(0);
      fill && series.fill(fill);
    };

    values.forEach((value) => {
      var dataSet = anychart.data.set(value);

      series = chartRef.current.column(dataSet);
      setupSeries(series, value.find(value => value.name)?.name || '', value.find(value => value.fill)?.fill);
    })

    // set chart padding
    chartRef.current.barGroupsPadding(0.3);

    // format numbers in y axis label to match browser locale
    // eslint-disable-next-line no-template-curly-in-string
    chartRef.current.yAxis().labels().format('{%Value}{groupsSeparator: }' + unit);

    // set titles for Y-axis
    chartRef.current.yAxis().title('');

    if (unit === '%') {
      chartRef.current.yScale().maximum(100);
    }

    // turn on legend
    chartRef.current.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0]);

    chartRef.current.interactivity().hoverMode('single');

    // eslint-disable-next-line no-template-curly-in-string
    chartRef.current.tooltip().format('{%Value}{groupsSeparator: }' + unit);

    scaleValues !== undefined && (chartRef.current.xScale() as unknown as anychart.scales.Ordinal).values(scaleValues)

    ref.current && chartRef.current.container(ref.current).draw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current, values, scaleValues])

  return (
    <div ref={ref} style={{width: '100%', height: '100%'}} />
  );
}
