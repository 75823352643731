import * as React from 'react';
import {ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {Tag, TagType3, User} from "../openapi";
import {ApiContext} from "./ApiContext";

interface DataContextProps {
  user?: User
  tagAll: Tag[]
  tagTypeAll: TagType3[]
  reload: () => void;
}

export const DataContext = React.createContext<DataContextProps>({
  user: undefined, tagAll: [], tagTypeAll: [], reload: () => {
  }
});

interface ApiContextProviderProps {
  children?: ReactNode | undefined
}

export function DataContextProvider({children}: ApiContextProviderProps) {
  const [tagAll, setTagAll] = useState<Tag[]>([]);
  const [tagTypeAll, setTagTypeAll] = useState<TagType3[]>([]);
  const [user, setUser] = useState<User>();

  const {api} = useContext(ApiContext)

  const reload = useCallback(() => {
    api.getApiTagAll().then(({data}) => setTagAll(data));
    api.getApiTagTypeAll().then(response => setTagTypeAll(response.data))
    api.getApiUserLogged().then(({data}) => setUser(data))
  }, [api]);

  useEffect(() => {
    reload();
  }, [api, reload]);

  return (
    <DataContext.Provider value={{tagTypeAll, tagAll, reload, user}}>
      {children}
    </DataContext.Provider>
  );
}
