import React, {useContext} from "react";
import {Container, Grid, Box, CardContent, Card} from "@mui/material";
import AutocompleteField from "./inputs/AutocompleteField";
import {Machine, Tag} from "../openapi";
import {DataContext} from "../context/DataContext";

interface FilterBarProps {
  values: number[]
  setValues: (values: number[]) => void
}

const tagMachines = (tag: Tag, tags: Tag[]): Machine[] => {
  const machines = tag.machines || [];
  tag.myTags?.forEach(myTag => {
    const tagData = tags.find(tag => tag.id === myTag.id);
    if (tagData) {
      tagData.machines?.forEach(myTagMachine => {
        if (!machines?.some(machine => machine.id === myTagMachine.id)) {
          machines?.push(myTagMachine)
        }
      })
    }
  })

  return machines
}

export default function FilterBar({values, setValues}: FilterBarProps) {
  const {tagAll, tagTypeAll} = useContext(DataContext)

  const data = tagTypeAll.filter(tagType => tagType.name !== 'intervention-generic');

  const machinesByTagType = data?.map(tagType => {
    const machines: Machine[] = []
    tagType.tags?.forEach(tag => {
      if (tag.id && values.includes(tag.id)) {
        tag.machines?.forEach(tagMachine => {
          if (!machines.some(machine => machine.id === tagMachine.id)) {
            machines.push(tagMachine);
          }
        })

      }
    })
    return machines;
  })

  const selectedTagsByGroups = new Map<string, Tag[]>([]);
  values.forEach(value => {
    const tag = tagAll.find(tag => tag.id === value);
    if (tag && tag.type?.name) {
      selectedTagsByGroups.set(tag.type?.name, [...selectedTagsByGroups.get(tag.type?.name) || [], tag])
    }
  })

  const filterTags = (tags: Tag[], index: number) => {
    machinesByTagType?.forEach((includedMachines, loopIndex) => {
      if (loopIndex !== index) {
        if (includedMachines.length > 0) {
          tags = tags.filter(tag => {
            const machineInList = (machine: Machine) => includedMachines.some(includedMachine => includedMachine.id === machine.id);
            return values.includes(tag.id || 0) || tagMachines(tag, tagAll)?.some(machineInList)
          })
        }
      }
    })
    tags = tags.filter(tag => values.includes(tag.id || 0) || tagMachines(tag, tagAll).length > 0)
    tags = tags.filter(tag => {
      if (!tag.myTags || tag.myTags.length === 0) {
        return true;
      }
      let containsOtherTagsFromMyTags = false;
      tag.myTags?.forEach(myTag => {
        const myTagData = tagAll.find(tag => tag.id === myTag.id);
        if (myTagData && myTagData.type?.name) {
          const otherTagsInGroup = selectedTagsByGroups.get(myTagData.type?.name) || []
          if (otherTagsInGroup.some(otherTagInGroup => !tag.myTags?.some(myTag => myTag.id === otherTagInGroup.id))) {
            containsOtherTagsFromMyTags = true;
          }
        }
      })

      return !containsOtherTagsFromMyTags;
    })
    tags = tags.filter(tag => {
      if (!tag.tagsWithMe || tag.tagsWithMe.length === 0) {
        return true;
      }
      let containsOtherTagsFromTagsWithMe = false;
      tag.tagsWithMe?.forEach(tagWithMe => {
        const tagWithMeData = tagAll.find(tag => tag.id === tagWithMe.id);
        if (tagWithMeData && tagWithMeData.type?.name) {
          const otherTagsInGroup = selectedTagsByGroups.get(tagWithMeData.type?.name) || []
          if (otherTagsInGroup.some(otherTagInGroup => !tag.tagsWithMe?.some(myTag => myTag.id === otherTagInGroup.id))) {
            containsOtherTagsFromTagsWithMe = true;
          }
        }
      })

      return !containsOtherTagsFromTagsWithMe;
    })
    return tags;
  }

  return (
    <Box mt={4}>
      <Container maxWidth="xl">
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {
                data?.map((item, index) => <Grid item xs={4} key={item.id}>
                    {item.tags &&
                        <AutocompleteField
                            label={item.description || ''}
                            tags={filterTags(item.tags, index)}
                            values={values}
                            setValues={setValues}
                        />
                    }
                  </Grid>
                )}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
