import {useContext, useEffect, useState} from "react";
import {ApiContext} from "../context/ApiContext";
import {GraphSummaryResponse} from "../openapi";
import {
  DataShape,
  formatMetricTrendDataForChart,
  getTrendDataSum,
  sumFilter,
  trendFilter,
  trendLastYearFilter,
} from "../helpers/metric";
import {formatDateForBackend, Range, trendFrom, trendLastYearFrom, trendLastYearTo, trendTo} from "../helpers/date";
import {AxiosResponse} from 'axios';
import {roundToDecimalPlaces} from "../helpers/number";

type SumEndpoint = 'postApiMetricsOeeSum' | 'postApiMetricsTeepSum' | 'postApiMetricsMttrSum' | 'postApiMetricsMtbfSum';
type TrendEndpoint =
  'postApiMetricsOeeTrend'
  | 'postApiMetricsTeepTrend'
  | 'postApiMetricsMttrTrend'
  | 'postApiMetricsMtbfTrend';

export type Metric = 'oee' | 'teep' | 'mttr' | 'mtbf';

export const isPercentMetric = (metric: Metric) => metric === 'oee' || metric === 'teep';

export const sumEndpointFromMetric = (metric: Metric): SumEndpoint => {
  switch (metric) {
    case "oee":
      return 'postApiMetricsOeeSum';
    case "teep":
      return 'postApiMetricsTeepSum';
    case "mttr":
      return 'postApiMetricsMttrSum';
    case "mtbf":
      return 'postApiMetricsMtbfSum';
  }
}

export const trendEndpointFromMetric = (metric: Metric): TrendEndpoint => {
  switch (metric) {
    case "oee":
      return 'postApiMetricsOeeTrend';
    case "teep":
      return 'postApiMetricsTeepTrend';
    case "mttr":
      return 'postApiMetricsMttrTrend';
    case "mtbf":
      return 'postApiMetricsMtbfTrend';
  }
}

export const useMetricSumBySingleTag = (from: Date, to: Date, range: Range, tags: number[], endpoint: SumEndpoint) => {
  const {api} = useContext(ApiContext);
  const [data, setData] = useState<number[]>();

  const fromFormatted = formatDateForBackend(from);
  const toFormatted = formatDateForBackend(to);
  const tagsFormatted = tags.join(';');

  useEffect(() => {
    const promises: Promise<AxiosResponse<GraphSummaryResponse, any>>[] = [];
    tags.forEach(tag => {
      promises.push(api[endpoint](sumFilter(from, to, range, [tag])))
    })
    Promise.all(promises).then((data) => setData(data.map(response => roundToDecimalPlaces(response.data.data || 0, 2))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, fromFormatted, toFormatted, api, tagsFormatted, endpoint]);

  return data;
}

export const useMetricSum = (from: Date, to: Date, range: Range, tags: number[], endpoint: SumEndpoint) => {
  const {api} = useContext(ApiContext);
  const [data, setData] = useState<GraphSummaryResponse>();

  const fromFormatted = formatDateForBackend(from);
  const toFormatted = formatDateForBackend(to);
  const tagsFormatted = tags.join(';');

  useEffect(() => {
    api[endpoint](sumFilter(from, to, range, tags)).then((response) => setData(response.data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, fromFormatted, toFormatted, api, tagsFormatted, endpoint]);

  return data;
}

export const useMetricTrend = (from: Date, to: Date, range: Range, tags: number[], endpoint: TrendEndpoint, sumEndpoint: SumEndpoint, period: 'thisYear' | 'lastYear' = 'thisYear') => {
  const {api} = useContext(ApiContext);
  const [trendData, setTrendData] = useState<DataShape[]>([]);

  const fromFormatted = formatDateForBackend(from);
  const toFormatted = formatDateForBackend(to);
  const tagsFormatted = tags.join(';');

  useEffect(() => {
    if (range !== Range.Months3 && range !== Range.Months6) {
      const filter = period === 'thisYear' ? trendFilter(from, to, range, tags) : trendLastYearFilter(from, to, range, tags);
      api[endpoint](filter).then((response) => {
        const trendData = response.data ? formatMetricTrendDataForChart(response.data, range) : [];
        if (trendData?.[0]?.[0] === 'Prosinec' && trendData?.length > 1) {// tmp fix to remove December prev year / TODO fix on BE
          trendData.shift();
        }
        setTrendData(trendData)
      })
    } else {
      const trendFromDate = period === 'thisYear' ? trendFrom(from, to, range) : trendLastYearFrom(from, to, range);
      const trendToDate = period === 'thisYear' ? trendTo(from, to, range) : trendLastYearTo(from, to, range);
      getTrendDataSum(api, sumEndpoint, trendFromDate, trendToDate, range, tags, setTrendData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, fromFormatted, toFormatted, api, tagsFormatted, endpoint, period, sumEndpoint]);

  return trendData;
}
