import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {Tag} from "../../openapi";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

interface AutocompleteFieldProps {
  label: string
  tags: Tag[]
  values: number[]
  setValues: (values: number[]) => void
}

export default function SingleAutocompleteField({label, tags, values, setValues}: AutocompleteFieldProps) {
  const otherValues = values.filter(id => !tags.some(tag => tag.id === id));

  return (
    <Autocomplete
      value={tags.find(tag => tag.id && values.includes(tag.id))}
      onChange={(_, newValue) => setValues([...otherValues, newValue?.id as number])}
      options={tags}
      getOptionLabel={(option) => option.name || ''}
      renderOption={(props, option, {selected}) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{marginRight: 8}}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder="Hledat"/>
      )}
    />
  );
}
