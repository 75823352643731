import React, {useCallback} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {Metric} from "../data/metric";

const style = {fontSize: 14, cursor: 'pointer', textDecoration: 'underline', mx: 0.5};

export default function TopBarMetricLink({tags, name, onClick}: { tags: string, name: string, onClick: () => void }) {
  const linkToMetric = useCallback((metric: Metric | 'pareto') => {
    return `/data/${metric}?tags=${tags}`;
  }, [tags])

  return (
    <Box key={tags} sx={{display: 'flex', flexDirection: 'column', width: 250}}>
      <Typography variant="h4">{name}</Typography>
      <Box sx={{mx: -0.5, mb: 1, mt: -0.5, color: '#9EB8C6'}}>
        <Link to={linkToMetric('oee')} state={{force: true}} onClick={onClick}>
          <Typography component="span" sx={style}>OEE</Typography>
        </Link>
        |
        <Link to={linkToMetric('teep')} state={{force: true}} onClick={onClick}>
          <Typography component="span" sx={style}>TEEP</Typography>
        </Link>
        |
        <Link to={linkToMetric('mttr')} state={{force: true}} onClick={onClick}>
          <Typography component="span" sx={style}>MTTR</Typography>
        </Link>
        |
        <Link to={linkToMetric('mtbf')} state={{force: true}} onClick={onClick}>
          <Typography component="span" sx={style}>MTBF</Typography>
        </Link>
        |
        <Link to={linkToMetric('pareto')} state={{force: true}} onClick={onClick}>
          <Typography component="span" sx={style}>Pareto</Typography>
        </Link>
      </Box>
    </Box>
  )
}
