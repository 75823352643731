import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {Box, Container, Stack, Paper, TextField, IconButton, Typography} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {
  add,
  sub,
  previousSunday,
  startOfMonth,
  endOfMonth,
  format,
  startOfYear,
  startOfQuarter,
  endOfQuarter, endOfYear, isValid, addQuarters, subQuarters
} from 'date-fns';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Range} from '../helpers/date';

interface DateRangeSelectionProps {
  range: Range
  setRange: (range: Range) => void
  from: Date
  setFrom: (from: Date) => void
  to: Date
  setTo: (to: Date) => void
}

export default function DateRangeSelection({range, setRange, from, setFrom, to, setTo}: DateRangeSelectionProps) {
  const handleRange = (
    event: React.MouseEvent<HTMLElement>,
    newRange: Range,
  ) => {
    setRange(newRange);
    switch (newRange) {
      case Range.Week:
        setFrom(add(sub(previousSunday(new Date()), {weeks: 1}), {days: 1}));
        setTo(previousSunday(new Date()))
        break;
      case Range.Month:
        setFrom(startOfMonth(sub(new Date(), {months: 1})));
        setTo(endOfMonth(sub(new Date(), {months: 1})))
        break;
      case Range.Months3:
        setFrom(startOfQuarter(sub(new Date(), {months: 3})));
        setTo(endOfQuarter(sub(new Date(), {months: 3})));
        break;
      case Range.Months6:
        let from = startOfYear(new Date());
        let to = subQuarters(endOfYear(new Date()), 2);
        if (to >= (new Date())) {
          from = subQuarters(from, 2);
          to = subQuarters(to, 2);
        }
        setFrom(from);
        setTo(to);
        break;
      case Range.Year:
        setFrom(startOfYear(sub(new Date(), {years: 1})));
        setTo(endOfYear(sub(new Date(), {years: 1})))
        break;
    }
  };

  const onPreviousClick = () => {
    switch (range) {
      case Range.Week:
        setFrom(sub(from, {weeks: 1}))
        setTo(sub(to, {weeks: 1}))
        break;
      case Range.Month:
        setFrom(startOfMonth(sub(from, {months: 1})))
        setTo(endOfMonth(sub(to, {months: 1})))
        break;
      case Range.Months3:
        setFrom(startOfMonth(sub(from, {months: 3})))
        setTo(endOfMonth(sub(to, {months: 3})))
        break;
      case Range.Months6:
        setFrom(startOfMonth(subQuarters(from, 2)))
        setTo(endOfMonth(subQuarters(to, 2)))
        break;
      case Range.Year:
        setFrom(startOfYear(sub(from, {years: 1})))
        setTo(endOfYear(sub(to, {years: 1})))
        break;
    }
  }

  const onForwardClick = () => {
    switch (range) {
      case Range.Week:
        setFrom(add(from, {weeks: 1}))
        setTo(add(to, {weeks: 1}))
        break;
      case Range.Month:
        setFrom(startOfMonth(add(from, {months: 1})))
        setTo(endOfMonth(add(to, {months: 1})))
        break;
      case Range.Months3:
        setFrom(startOfMonth(add(from, {months: 3})))
        setTo(endOfMonth(add(to, {months: 3})))
        break;
      case Range.Months6:
        setFrom(startOfMonth(addQuarters(from, 2)))
        setTo(endOfMonth(addQuarters(to, 2)))
        break;
      case Range.Year:
        setFrom(startOfYear(add(from, {years: 1})))
        setTo(endOfYear(add(to, {years: 1})))
        break;
    }
  }

  return (
    <>
      <Box mt={4}>
        <Container maxWidth="xl">
          <Stack spacing={4} direction="row" alignItems="center">
            <Typography variant="caption">Hodnoty za</Typography>
            <Paper>
              <ToggleButtonGroup
                value={range}
                exclusive
                onChange={handleRange}
                aria-label="text alignment"
                style={{height: '100%'}}
              >
                <ToggleButton value={Range.Week} aria-label="Týden">
                  <Typography variant="body2">Týden</Typography>
                </ToggleButton>
                <ToggleButton value={Range.Month} aria-label="Měsíc">
                  <Typography variant="body2">Měsíc</Typography>
                </ToggleButton>
                <ToggleButton value={Range.Months3} aria-label="3 měsíce">
                  <Typography variant="body2">3 měsíce</Typography>
                </ToggleButton>
                <ToggleButton value={Range.Months6} aria-label="6 měsíců">
                  <Typography variant="body2">6 měsíců</Typography>
                </ToggleButton>
                <ToggleButton value={Range.Year} aria-label="Rok">
                  <Typography variant="body2">Rok</Typography>
                </ToggleButton>
                <ToggleButton value={Range.Custom} aria-label="Vlastní">
                  <Typography variant="body2">Vlastní</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Paper>
            <Stack direction="row" spacing={1}>
              <IconButton aria-label="delete" onClick={onPreviousClick} disabled={range === Range.Custom}>
                <ArrowBackIosNewIcon/>
              </IconButton>
              <DatePicker
                label="Od"
                value={from}
                onChange={(newValue) => {
                  newValue && isValid(newValue) && setFrom(newValue) ;
                  newValue && isValid(newValue) && setRange(Range.Custom);
                }}
                disableFuture
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="Do"
                value={to}
                onChange={(newValue) => {
                  newValue && isValid(newValue) && setTo(newValue);
                  newValue && isValid(newValue) && setRange(Range.Custom);
                }}
                disableFuture
                shouldDisableDate={(date) => from ? date <= from : false}
                renderInput={(params) => <TextField {...params} />}
              />
              <IconButton aria-label="delete" onClick={onForwardClick} disabled={range === Range.Custom}>
                <ArrowForwardIosIcon/>
              </IconButton>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box mt={2}>
        <Container maxWidth="xl">
          <Typography variant="caption">Období {format(from, 'dd.MM.yyyy')} - {format(to, 'dd.MM.yyyy')}</Typography>
        </Container>
      </Box>
    </>
  );
}
