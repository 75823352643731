import React, {useContext} from "react";
import HomepageDataRowBlock from "./HomepageDataRowBlock";
import {Metric, sumEndpointFromMetric, useMetricSum, useMetricSumBySingleTag} from "../../data/metric";
import {Range} from "../../helpers/date";
import {DataContext} from "../../context/DataContext";
import {Tag} from "../../openapi";
import {roundToDecimalPlaces} from "../../helpers/number";

interface HomepageOeeBlockProps {
  from: Date;
  to: Date;
  metric: Metric
}

export default function HomepageMetricBlock({from, to, metric}: HomepageOeeBlockProps) {
  const sumEndpoint = sumEndpointFromMetric(metric)
  const sumValue = useMetricSum(from, to, Range.Week, [], sumEndpoint)?.data || 0;

  const {tagTypeAll} = useContext(DataContext);
  const companyTags = tagTypeAll?.find(tagType => tagType.name === 'division')?.tags || [];
  const dataByTag = useMetricSumBySingleTag(from, to, Range.Week, companyTags?.map(tag => tag.id || 0), sumEndpoint);
  const values = companyTags.map((tag: Tag, index) => {
    return {tag, value: dataByTag?.[index] || 0};
  })

  return (
    <HomepageDataRowBlock sumValue={roundToDecimalPlaces(sumValue, 2)} values={values} metric={metric} />
  )
}
