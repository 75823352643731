import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {Tag} from "../../openapi";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

interface AutocompleteFieldProps {
  label: string
  tags: Tag[]
  values: number[]
  setValues: (values: number[]) => void
}

export default function AutocompleteField({label, tags, values, setValues}: AutocompleteFieldProps) {
  const otherValues = values.filter(id => !tags.some(tag => tag.id === id));
  const allSelected = tags.length === values.length - otherValues.length;

  const filter = createFilterOptions<Tag>();

  const toggleSelectAll = () => {
    if (allSelected) {
      setValues([...otherValues]);
    } else {
      setValues([...otherValues, ...tags.map(tag => tag.id).filter(id => id !== undefined) as number[]]);
    }
  }

  return (
    <Autocomplete
      value={tags.filter(tag => tag.id && values.includes(tag.id))}
      onChange={(_, newValues, reason) => {
        if (newValues.some(option => (option as any).value === 'select-all')) {
          toggleSelectAll();
        }  else {
          setValues([...otherValues, ...newValues.map(tag => tag.id).filter(id => id !== undefined) as number[]])
        }
      }}
      multiple
      limitTags={2}
      options={tags}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name || 'Vybrat vše'}
      renderOption={(props, option, {selected}) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{marginRight: 8}}
            checked={selected || ((option as any).value === 'select-all' && allSelected)}
          />
          {option.name || 'Vybrat vše'}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder="Hledat"/>
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return [{value: "select-all"} as unknown as Tag, ...filtered];
      }}
    />
  );
}
