/// <reference types="anychart" />

import * as React from 'react';
import 'anychart';
import {createRef, useEffect, useRef} from "react";
import {roundToDecimalPlaces} from "../../helpers/number";

interface PieChartProps {
  values: {x: string, value: number, fill?: string|null}[]
}

export default function PieChart({values}: PieChartProps) {
  const ref = createRef<HTMLDivElement>()
  var pieGauge = anychart.pie();
  const chartRef = useRef(pieGauge);

  useEffect(() => {
    const dataSet = anychart.data.set(values);

    chartRef.current.data(dataSet);
    chartRef.current.innerRadius('60%');

    chartRef.current.labels().position('outside');

    chartRef.current.legend().enabled(false);

    const interactivity = chartRef.current.interactivity();
    interactivity.selectionMode("none");

    const sumOfValues = values.map(({value}) => value).reduce((acc, value) => acc + value, 0);
    chartRef.current.tooltip().format(((e: any) => `Prostoj: ${e.value}h (${roundToDecimalPlaces(e.value / sumOfValues * 100, 2)}%)`) as any);

    chartRef.current.bounds('-5%', '-5%', '110%', '110%');

    ref.current && chartRef.current.container(ref.current).draw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current, values])

  return (
    <div ref={ref} style={{width: '100%', height: '100%'}}/>
  );
}
