import React, {useContext} from "react";
import {DataContext} from "../context/DataContext";
import EditableTagList from "../components/tag/EditableTagList";

export default function InterventionGroups() {
  const {tagTypeAll} = useContext(DataContext)
  const type = tagTypeAll.find(type => type.name === 'intervention-generic');

  if (!type) {
    return <>Skupina intervention-generic neexistuje.</>;
  }

  return <EditableTagList tagType={type} />
}
